import React from "react";
import {inject, observer} from "mobx-react";
import {observable, toJS} from "mobx";
import {Button, Form, Grid, Modal, Table} from "semantic-ui-react";
import config from "../../config/main.config";
import {getTokenFromLocalStorage, isAdmin} from "../helper/util";
import userStore from "../stores/userStore";
import history from "../helper/browserHistory";
import {withTranslation} from "react-i18next";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import sharedConfig from "../../../shared/sharedConfig";
import Checkbox from "semantic-ui-react/dist/commonjs/modules/Checkbox";

const DEFAULT_PRODUCT = {
    id: "",
    name: "",
    pzn: "",
    admissionCountries: ""
};

@inject("stores")
@withTranslation()
@observer
export default class ProductPage extends React.Component {

    @observable products = [];
    @observable filter = "";
    @observable loading = true;

    @observable addModalOpen = false;

    //User data used for add and edit
    //ALWAYS RESET AFTER USING
    @observable
    addEditIndex = DEFAULT_PRODUCT;

    @observable deleteModalOpen = false;
    @observable deleteUserId = "";

    @observable errorMessage = "";
    @observable errorModalOpen = false;


    /**
     * Scrolls page up when loaded
     */
    componentDidMount() {
        if (userStore.userFromServer == null || !isAdmin()) {
            history.push("/" + this.props.i18n.language + "/");
        }

        window.scrollTo(0, 0);

        this.getProducts();

    }

    getProducts() {
        fetch(config.BASE_URL + "products/all", {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((resp) => resp.json())
            .then(data => {
                this.products = data;
                this.loading = false;
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    showDeleteModal(id) {
        this.deleteUserId = id;
        this.deleteModalOpen = true;

    }

    deleteIndication() {
        fetch(config.BASE_URL + 'products/' + this.deleteUserId, {
            method: 'DELETE',
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then(response => {
            if (response.status >= 200 && response.status < 300) {
                //Load Users
                this.getProducts();
                this.deleteUserId = "";
                this.deleteModalOpen = false;
            } else {
                this.errorMessage = "Network error";
                this.errorModalOpen = true;
            }
        }).catch(function (error) {
            console.log("bad", error);
        });
    }


    addUser() {
        if (this.addEditIndex.name.length === 0) {
            return;
        }
        let method = "POST";
        if (this.addEditIndex.id !== "") {
            method = "PUT";
        }
        return fetch(config.BASE_URL + "products", {
            method: method,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify(toJS(this.addEditIndex))
        })
            .then(response => {
                this.loading = false;
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        if (json.hasOwnProperty("id")) {
                            //Load Users
                            this.getProducts();
                            this.addModalOpen = false;
                            this.addEditIndex = DEFAULT_PRODUCT
                        } else {
                            this.errorMessage = "Network error";
                            this.errorModalOpen = true;
                        }
                    });

                } else {
                    this.errorMessage = "Network error";
                    this.errorModalOpen = true;
                }
            })
            .catch(
                error => {
                    this.errorMessage = error;
                    this.errorModalOpen = true;
                }
            );

    }

    handleInputChange(type, event) {
        this.addEditIndex[type] = event.target.value;
    }

    openModal(open) {
        this.addModalOpen = open;
        this.addEditIndex = DEFAULT_PRODUCT;
    }

    showEditModal(index, id) {
        this.addEditIndex = {
            id: this.products[index].id,
            name: this.products[index].name,
            pzn: this.products[index].pzn,
            admissionCountries: this.products[index].admissionCountries
        };
        this.addModalOpen = true;
    }

    getFilteredProducts(filter, products) {
        let filteredProducts = [];
        for (let i = 0; i < products.length; i++) {
            if (filter.length === 0 ||
                products[i].name.toLowerCase().includes(filter.toLowerCase()) ||
                products[i].pzn.toLocaleLowerCase().includes(filter.toLowerCase())) {
                filteredProducts.push(
                    <Table.Row key={i}>
                        <Table.Cell>{products[i].name}</Table.Cell>
                        <Table.Cell>{products[i].pzn}</Table.Cell>
                        <Table.Cell>{products[i].admissionCountries}</Table.Cell>
                        <Table.Cell>
                            <button className="ui basic icon negative button" role="button"
                                    onClick={this.showDeleteModal.bind(this, products[i].id)}>
                                <i aria-hidden="true"
                                   className="remove icon"/>
                            </button>
                        </Table.Cell>
                        <Table.Cell>
                            <button className="ui basic icon positive button" role="button"
                                    onClick={this.showEditModal.bind(this, i, products[i].id)}>
                                <i aria-hidden="true"
                                   className="edit icon"/>
                            </button>
                        </Table.Cell>
                    </Table.Row>
                );

            }
        }

        return filteredProducts;
    }


    /**
     * Returns Blog page
     * @return {XML} html of this component
     */
    render() {
        const {t} = this.props;

        let tableAuthors = this.getFilteredProducts(this.filter, this.products);
        const admissionCountries = sharedConfig.LANGUAGES.map(language => (
            <Checkbox
                label={language.label}
                style={{marginRight: "10px"}}
                value={language.language}
                checked={typeof this.addEditIndex.admissionCountries !== "undefined" ? this.addEditIndex.admissionCountries.includes(language.label) : false}
                onChange={(event, data) => {
                    if (data.checked) {
                        typeof this.addEditIndex.admissionCountries === "undefined" ? this.addEditIndex.admissionCountries = data.value + " " : this.addEditIndex.admissionCountries += data.value + " ";
                    } else {
                        this.addEditIndex.admissionCountries = this.addEditIndex.admissionCountries.replace(data.value, "");
                    }
                }}
            />));

        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column computer={5} tablet={10} mobile={14} className={"underline-column"}>
                        <h1 className={"page-header"}>
                            {t('titles.products')}
                        </h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Input value={this.filter} onChange={e => this.filter = e.target.value}/>
                        <Button positive onClick={this.openModal.bind(this, true)}>Hinzufügen</Button>
                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    <Table.HeaderCell>PZN</Table.HeaderCell>
                                    <Table.HeaderCell>Zugelassen in</Table.HeaderCell>
                                    <Table.HeaderCell>Entfernen</Table.HeaderCell>
                                    <Table.HeaderCell>Bearbeiten</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {tableAuthors}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
                <Modal open={this.addModalOpen} onClose={this.openModal.bind(this, false)}
                       dimmer={"blurring"}
                       style={{"margin": "auto"}}>
                    <Modal.Header>{this.addEditIndex.id === "" ? "Produkt hinzufügen" : "Produkt bearbeiten"}</Modal.Header>
                    <Modal.Content scrolling>
                        <Form>
                            <Form.Input fluid label='Name' placeholder='Name' type="text"
                                        value={this.addEditIndex.name}
                                        onChange={this.handleInputChange.bind(this, 'name')}/>
                            <Form.Input fluid label='PZN' placeholder='PZN' type="text"
                                        value={this.addEditIndex.pzn}
                                        onChange={this.handleInputChange.bind(this, 'pzn')}/>
                            <Form.Field>
                                <label>{t("create_product.admissionCountries")}</label>
                                {admissionCountries}
                            </Form.Field>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button positive icon='checkmark' labelPosition='right'
                                content={this.addEditIndex.id === "" ? "hinzufügen" : "bearbeiten"}
                                onClick={this.addUser.bind(this)}/>
                    </Modal.Actions>
                </Modal>

                <Modal
                    dimmer={"blurring"}
                    style={{"margin": "auto"}}
                    open={this.deleteModalOpen} onClose={() => this.deleteModalOpen = false}>
                    <Modal.Header>Indikation entfernen</Modal.Header>
                    <Modal.Content>
                        <p>Sind Sie sich sicher diese Indikation zu löschen?</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button positive icon='checkmark' labelPosition='right' content='Löschen'
                                onClick={this.deleteIndication.bind(this)}/>
                    </Modal.Actions>
                </Modal>

                <Modal open={this.errorModalOpen} onClose={() => this.errorModalOpen = false}
                       dimmer={"blurring"}
                       style={{"margin": "auto"}}>
                    <Modal.Header>Error</Modal.Header>
                    <Modal.Content>
                        <p>{this.errorMessage}</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button positive icon='checkmark' labelPosition='right' content='OK'
                                onClick={() => {
                                    this.errorModalOpen = false;
                                    this.errorMessage = ""
                                }}/>
                    </Modal.Actions>
                </Modal>
            </Grid>
        );
    }
}