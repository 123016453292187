import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {observer} from "mobx-react";
import config from "../../config/main.config";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";

@withTranslation()
@observer
export default class SeparatorRow extends Component {

    render() {
        return (
            <Grid.Row centered>
                <Grid.Column computer={1} tablet={3} mobile={5} className={"separator-image-column"}>
                    <Image
                        alt={"Separator"}
                        fluid
                        src={config.PUBLIC_URL + "images/separator_blue.svg"}/>
                </Grid.Column>
            </Grid.Row>
        );
    }
}
