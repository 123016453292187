import React from "react";
import i18n from "../i18n";
import {observer} from "mobx-react";
import {withTranslation} from "react-i18next";
import {observable} from "mobx";
import config from "../../config/main.config";
import history from "../helper/browserHistory";
import SeparatorRow from "../components/SeparatorRow";
import EditSimpleValueComponent from "../components/EditSimpleValueComponent";

import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Checkbox from "semantic-ui-react/dist/commonjs/modules/Checkbox";
import userStore from "../stores/userStore";

const regexpMail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const regexpPhone = /^\+?\d*$/;

const salutationOptionsDe = [
    {text: "Herr", value: "Herr"},
    {text: "Frau", value: "Frau"},
    {text: "Keine Angabe", value: "Keine Angabe"}
];

const salutationOptionsEn = [
    {text: "Mr.", value: "Mr."},
    {text: "Ms./Mrs.", value: "Ms./Mrs."},
    {text: "No information", value: "No information"}
];

const sexOptionsDe = [
    {text: "Männlich", value: "Männlich"},
    {text: "Weiblich", value: "Weiblich"},
    {text: "Keine Angabe", value: "Keine Angabe"}
];

const sexOptionsEn = [
    {text: "Male", value: "Man"},
    {text: "Female", value: "Woman"},
    {text: "No information", value: "No information"}
];

const subjectOptionsDe = [
    {text: "ApothekerIn", value: "ApothekerIn"},
    {text: "ÄrztIn", value: "Arzt/Ärztin"},
    {text: "Hebamme", value: "Hebamme"},
    {text: "HeilpraktikerIn", value: "HeilpraktikerIn"},
    {text: "LogopädIn", value: "LogopädIn"},
    {text: "MasseurIn", value: "MasseurIn"},
    {text: "PhysiotherapeutIn", value: "PhysiotherapeutIn"},
    {text: "PodologIn", value: "Podologe/Podologin"},
    {text: "PTA / PKA", value: "PTA"},
    {text: "TherapeutIn", value: "TherapeutIn"},
    {text: "TierärztIn", value: "Tierarzt/Tierärztin"},
    {text: "ZahnärztIn", value: "Zahnarzt/Zahnärztin"},
    {text: "Sonstiges", value: "Sonstiges"}
];


const subjectOptionsEn = [
    {text: "Dentist", value: "Dentist"},
    {text: "Hospital", value: "Hospital"},
    {text: "Logopedist", value: "Logopedist"},
    {text: "Masseur", value: "Masseur"},
    {text: "Medical Doctor", value: "Medical Doctor"},
    {text: "Midwife", value: "Midwife"},
    {text: "Naturopathic Doctor", value: "Naturopathic Doctor"},
    {text: "Pharmacist", value: "Pharmacist"},
    {text: "Pharmacy technician", value: "Pharmacy technician"},
    {text: "Podiatrist", value: "Podiatrist"},
    {text: "Physiotherapist", value: "Physiotherapist"},
    {text: "Physician", value: "Physician"},
    {text: "PTA / PKA", value: "PTA"},
    {text: "Retail", value: "Retail"},
    {text: "Therapist", value: "Therapist"},
    {text: "Veterinarian", value: "Veterinarian"},
    {text: "Wholesale", value: "Wholesale"},
    {text: "Others", value: "Others"}
];

@withTranslation()
@observer
export default class KasuistikPage extends React.Component {

    //Therapist Data
    @observable
    salutation = "";
    @observable
    salutationError = false;
    @observable
    title = "";
    @observable
    firstName = "";
    @observable
    lastName = "";
    @observable
    phone = "";
    @observable
    email = "";
    @observable
    subject = "";

    @observable
    conditionsChecked = false;
    @observable
    firmError = false;
    @observable
    firstNameError = false;
    @observable
    titleError = false;
    @observable
    lastNameError = false;
    @observable
    phoneError = false;
    @observable
    emailError = false;
    @observable
    subjectError = false;
    @observable
    messageError = false;
    @observable
    conditionsCheckedError = false;

    @observable
    country = "";
    @observable
    countryError = false;

    @observable
    postalCode = "";
    @observable
    postalCodeError = false;

    @observable
    place = "";
    @observable
    placeError = false;

    @observable
    houseNumber = "";
    @observable
    houseNumberError = false;

    //Therapy Data
    @observable
    anamnese = "";
    @observable
    anamneseError = false;
    @observable
    therapy = "";
    @observable
    therapyError = false;

    @observable
    diagnose = "";
    @observable
    diagnoseError = false;

    @observable
    results = "";
    @observable
    resultsError = false;

    //Patient Data
    @observable
    age = "";
    @observable
    ageError = false;

    @observable
    sex = "";
    @observable
    sexError = false;

    @observable
    modalOpen = false;
    @observable
    modalMessage = "";


    componentDidMount() {
        window.scrollTo(0, 0);
    }


    handleSearchChange(type, event, data) {
        switch (type) {
            //patient
            case "sex":
                this.sex = data.value;
                break;
            case "age":
                this.age = data.value;
                break;
            //therapy
            case "therapy":
                this.therapy = data.value;
                break;
            case "anamnese":
                this.anamnese = data.value;
                break;
            case "diagnose":
                this.diagnose = data.value;
                break;
            case "results":
                this.results = data.value;
                break;
            //therapist
            case "salutation":
                this.salutation = data.value;
                break;
            case "firstName":
                this.firstName = data.value;
                break;
            case "title":
                this.title = data.value;
                break;
            case "lastName":
                this.lastName = data.value;
                break;
            case "phone":
                if (data.value.match(regexpPhone) || data.value === '') {
                    this.phone = data.value;
                }
                break;
            case "email":
                this.email = data.value;
                break;
            case "subject":
                this.subject = data.value;
                break;
            case "message":
                this.message = data.value;
                break;
            case "postalCode":
                this.postalCode = data.value;
                break;
            case "country":
                this.country = data.value;
                break;
            case "place":
                this.place = data.value;
                break;
            case "houseNumber":
                this.houseNumber = data.value;
                break;
        }
    }

    submitMessage() {
        let canSend = this.checkMissingFields();

        let language = "de-DE";

        if (i18n) {
            language = this.props.i18n.language;
        }

        if (canSend) {
            //Send to server
            let userId = "";
            if (userStore.userFromServer) {
                userId = userStore.userFromServer.id;
            }

            let data = {
                language: language,
                salutation: this.salutation,
                title: this.title,
                firstName: this.firstName,
                lastName: this.lastName,
                subject: this.subject,
                country: this.country,
                houseNumber: this.houseNumber,
                place: this.place,
                postalCode: this.postalCode,
                phone: this.phone,
                email: this.email,
                sex: this.sex,
                age: this.age,
                anamnese: this.anamnese,
                therapy: this.therapy,
                diagnose: this.diagnose,
                results: this.results,
                id: userId
            };

            let self = this;

            fetch(config.BASE_URL + 'contact/expert', {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            }).then(function (response) {
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        if (json.hasOwnProperty("success") && json) {
                            self.modalMessage = i18n.t('kasuistik_form.response_success');
                        } else {
                            self.modalMessage = i18n.t('contact_form.response_error');
                        }
                        self.modalOpen = true;
                    });
                } else {
                    self.modalMessage = i18n.t('contact_form.response_error');
                    self.modalOpen = true;
                }
            }).catch(() => {
                self.modalMessage = i18n.t('contact_form.response_error');
                self.modalOpen = true;
            });
        }
    }

    render() {
        let language = "de-DE";
        const {i18n} = this.props;

        if (i18n) {
            language = i18n.language;
        }
        let languageForURL = "/de-DE";

        if (language !== "de-DE") {
            languageForURL = "/" + language;
        }

        return (
            <Grid
                style={{backgroundImage: "url(" + config.PUBLIC_URL + "images/Pflanze1.png), url(" + config.PUBLIC_URL + "images/Pflanze2.png)",}}
                className={"main-background-images"}>

                <Modal
                    style={{"margin": "auto"}}
                    open={this.modalOpen}
                    onClose={() => this.modalOpen = false}
                    size='small'>
                    <Modal.Content>
                        <h3>{this.modalMessage}</h3>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='green' onClick={() => history.push("/"+this.props.i18n.language+"/overview")}>
                            <Icon name='checkmark'/> OK
                        </Button>
                    </Modal.Actions>
                </Modal>
                <SeparatorRow/>
                <Grid.Row centered>
                    <Grid.Column computer={8} tablet={14} mobile={16} textAlign={"center"}>
                        <h1 className={"landing-title-header"}>
                            <EditSimpleValueComponent id={"kasuistik-subtitle-big-text-1-id"}/>
                        </h1>
                        <p style={{
                            "textAlign": "center",
                            "paddingBottom": "25px"
                        }}
                           dangerouslySetInnerHTML={{__html: i18n.t('contact_form.mandatory')}}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className={"no-top-padding"}>
                    <Grid.Column computer={8} tabel={14} mobile={16} className={"products-container"}>
                        <Grid padded>
                            <Grid.Row className={"card-shadow"}>
                                <Grid.Column>
                                    <Form className={"contact-form"}>
                                        <p className={"kasuistik-form-header-text"}>
                                            1. {i18n.t('kasuistik_form.patient_data')}
                                        </p>
                                        <Form.Group widths={"equal"}>
                                            <Form.Field>
                                                <label>{i18n.t('kasuistik_form.sex').toUpperCase()}</label>
                                                <Dropdown clearable
                                                          className={"contact-dropdown"}
                                                          options={language.startsWith("en") ? sexOptionsEn : sexOptionsDe}
                                                          selection
                                                          error={this.sexError}
                                                          value={this.sex}
                                                          onChange={this.handleSearchChange.bind(this, 'sex')}
                                                          icon={"angle down"}
                                                          placeholder={i18n.t('kasuistik_form.drop_sex')}
                                                />
                                            </Form.Field>
                                            <Form.Input
                                                label={i18n.t('kasuistik_form.age').toUpperCase()}
                                                value={this.age}
                                                error={this.ageError}
                                                onChange={this.handleSearchChange.bind(this, "age")}
                                            />
                                        </Form.Group>

                                        <p className={"kasuistik-form-header-text"}>
                                            2. {i18n.t('kasuistik_form.therapy_data')}
                                        </p>
                                        <Form.TextArea
                                            label={i18n.t('kasuistik_form.anamnese').toUpperCase()}
                                            value={this.anamnese}
                                            error={this.anamneseError}
                                            onChange={this.handleSearchChange.bind(this, "anamnese")}
                                        />
                                        <Form.TextArea
                                            label={i18n.t('kasuistik_form.diagnose').toUpperCase()}
                                            value={this.diagnose}
                                            error={this.diagnoseError}
                                            onChange={this.handleSearchChange.bind(this, "diagnose")}
                                        />

                                        <Form.TextArea
                                            label={i18n.t('kasuistik_form.therapy').toUpperCase()}
                                            value={this.therapy}
                                            error={this.therapyError}
                                            onChange={this.handleSearchChange.bind(this, "therapy")}
                                        />
                                        <Form.TextArea
                                            label={i18n.t('kasuistik_form.results').toUpperCase()}
                                            value={this.results}
                                            error={this.resultsError}
                                            onChange={this.handleSearchChange.bind(this, "results")}
                                        />


                                        <p className={"kasuistik-form-header-text"}>
                                            3. {i18n.t('kasuistik_form.therapist_data')}
                                        </p>
                                        <Form.Group widths={"equal"}>
                                            <Form.Field>
                                                <label>{i18n.t('contact_form.salutation').toUpperCase()}</label>
                                                <Dropdown clearable
                                                          className={"contact-dropdown"}
                                                          options={language.startsWith("en") ? salutationOptionsEn : salutationOptionsDe}
                                                          selection
                                                          error={this.salutationError}
                                                          value={this.salutation}
                                                          onChange={this.handleSearchChange.bind(this, 'salutation')}
                                                          icon={"angle down"}
                                                />
                                            </Form.Field>
                                            <Form.Input
                                                label={i18n.t('contact_form.person_title').toUpperCase()}
                                                value={this.title}
                                                error={this.titleError}
                                                onChange={this.handleSearchChange.bind(this, "title")}
                                            />
                                        </Form.Group>
                                        <Form.Group widths={"equal"}>
                                            <Form.Input
                                                label={i18n.t('contact_form.firstName').toUpperCase() + "*"}
                                                value={this.firstName}
                                                error={this.firstNameError}
                                                onChange={this.handleSearchChange.bind(this, "firstName")}
                                            />
                                            <Form.Input
                                                label={i18n.t('contact_form.lastName').toUpperCase() + "*"}
                                                value={this.lastName}
                                                error={this.lastNameError}
                                                onChange={this.handleSearchChange.bind(this, "lastName")}
                                            />
                                        </Form.Group>
                                        <Form.Field>
                                            <label>{i18n.t('contact_form.subject').toUpperCase()}</label>
                                            <Dropdown clearable
                                                      className={"contact-dropdown"}
                                                      options={language.startsWith("en") ? subjectOptionsEn : subjectOptionsDe}
                                                      selection
                                                      error={this.subjectError}
                                                      value={this.subject}
                                                      onChange={this.handleSearchChange.bind(this, 'subject')}
                                                      icon={"angle down"}
                                            />
                                        </Form.Field>
                                        <Form.Group widths={"equal"}>
                                            <Form.Input
                                                label={i18n.t('contact_form.country').toUpperCase()}
                                                value={this.country}
                                                error={this.countryError}
                                                onChange={this.handleSearchChange.bind(this, "country")}
                                            />
                                            <Form.Input
                                                label={i18n.t('contact_form.house_number').toUpperCase()}
                                                value={this.houseNumber}
                                                error={this.houseNumberError}
                                                onChange={this.handleSearchChange.bind(this, "houseNumber")}
                                            />
                                        </Form.Group>
                                        <Form.Group widths={"equal"}>
                                            <Form.Input
                                                label={i18n.t('contact_form.postal_code').toUpperCase()}
                                                value={this.postalCode}
                                                error={this.postalCodeError}
                                                onChange={this.handleSearchChange.bind(this, "postalCode")}
                                            />
                                            <Form.Input
                                                label={i18n.t('contact_form.place').toUpperCase()}
                                                value={this.place}
                                                error={this.placeError}
                                                onChange={this.handleSearchChange.bind(this, "place")}
                                            />
                                        </Form.Group>
                                        <Form.Group widths={"equal"}>
                                            <Form.Input
                                                label={i18n.t('contact_form.phone').toUpperCase()}
                                                value={this.phone}
                                                error={this.phoneError}
                                                onChange={this.handleSearchChange.bind(this, "phone")}
                                            />
                                            <Form.Input
                                                label={i18n.t('contact_form.email').toUpperCase() + "*"}
                                                value={this.email}
                                                error={this.emailError}
                                                onChange={this.handleSearchChange.bind(this, "email")}
                                            />
                                        </Form.Group>
                                        <Form.Field
                                            error={this.conditionsCheckedError}
                                            className={"accept-terms"}
                                        >
                                            <Checkbox
                                                style={{
                                                    "marginTop": "15px",
                                                    "marginRight": "15px",
                                                    "whiteSpace": "nowrap"
                                                }}
                                                onChange={() => this.conditionsChecked = !this.conditionsChecked}
                                                checked={this.conditionsChecked}
                                            />
                                            {i18n.t('contact_form.terms_conditions')}
                                            <a onClick={() => history.push(languageForURL + "/datenschutz")}
                                               className={"accept-terms-link"}>
                                                {i18n.t('contact_form.privacy_link_text')}
                                            </a>
                                        </Form.Field>
                                    </Form>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row centered>
                                <Grid.Column width={16} verticalAlign={"middle"}>
                                    <Button type='submit'
                                            className={"submit-button"}
                                            style={{
                                                backgroundImage: " url('" + config.PUBLIC_URL + "images/buttonorange.png" + "')"
                                            }}
                                            onClick={() => this.submitMessage()}>{i18n.t('contact_form.submit_kasu').toUpperCase()}
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );

    }

    checkMissingFields() {
        let canSend = true;

        //validate therapist data
        /*if (this.salutation.length > 0) {
            this.salutationError = false;
        } else {
            this.salutationError = true;
            canSend = false;
        }

        if (this.country.length > 0) {
            this.countryError = false;
        } else {
            this.countryError = true;
            canSend = false;
        }

        if (this.houseNumber.length > 0) {
            this.houseNumberError = false;
        } else {
            this.houseNumberError = true;
            canSend = false;
        }

        if (this.subject.length > 0) {
            this.subjectError = false;
        } else {
            this.subjectError = true;
            canSend = false;
        }

        if (this.postalCode.length > 0) {
            this.postalCodeError = false;
        } else {
            this.postalCodeError = true;
            canSend = false;
        }

        if (this.place.length > 0) {
            this.placeError = false;
        } else {
            this.placeError = true;
            canSend = false;
        }

        //Check patient Data
        if (this.sex.length > 0) {
            this.sexError = false;
        } else {
            this.sexError = true;
            canSend = false;
        }

        if (this.age.length > 0) {
            this.ageError = false;
        } else {
            this.ageError = true;
            canSend = false;
        }

        //check therapy data
        if (this.anamnese.length > 0) {
            this.anamneseError = false;
        } else {
            this.anamneseError = true;
            canSend = false;
        }
        //check therapy data
        if (this.therapy.length > 0) {
            this.therapyError = false;
        } else {
            this.therapyError = true;
            canSend = false;
        }
        if (this.diagnose.length > 0) {
            this.diagnoseError = false;
        } else {
            this.diagnoseError = true;
            canSend = false;
        }
        if (this.results.length > 0) {
            this.resultsError = false;
        } else {
            this.resultsError = true;
            canSend = false;
        }*/

        if (this.firstName.length > 0) {
            this.firstNameError = false;
        } else {
            this.firstNameError = true;
            canSend = false;
        }

        if (this.lastName.length > 0) {
            this.lastNameError = false;
        } else {
            this.lastNameError = true;
            canSend = false;
        }

        if (this.email.match(regexpMail)) {
            this.emailError = false;
        } else {
            this.emailError = true;
            canSend = false;
        }

        if (this.conditionsChecked) {
            this.conditionsCheckedError = false;
        } else {
            this.conditionsCheckedError = true;
            canSend = false;
        }


        return canSend
    }
}