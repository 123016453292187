import React from "react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import {withTranslation} from "react-i18next";
import userStore from "../stores/userStore";
import history from "../helper/browserHistory";
import {getTokenFromLocalStorage, showBlobFile} from "../helper/util";
import {observable, toJS} from "mobx";
import {observer} from "mobx-react";
import config from "../../config/main.config";
import {Table} from "semantic-ui-react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Checkbox from "semantic-ui-react/dist/commonjs/modules/Checkbox";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import DatePicker from "react-datepicker";
import sharedConfig from "../../../shared/sharedConfig";

if (typeof document !== "undefined") {
    require("react-datepicker/dist/react-datepicker.css");
}


const DEFAULT_FEEDBACK = null;

@withTranslation()
@observer
export default class FeedbackOverviewPage extends React.Component {

    @observable
    feedbacks = [];

    @observable
    chosenQuestionIds = [];
    @observable
    chosenFeedback = DEFAULT_FEEDBACK;
    @observable
    exportModalOpen = false;
    @observable
    deleteModalOpen = false;

    @observable
    checkedLanguagesForExport = [];

    @observable
    language = "de-DE";

    @observable
    start = "";
    @observable
    end = "";

    componentDidMount() {
        if (userStore.userFromServer == null || !userStore.isAdmin) {
            history.push("/" + this.props.i18n.language + "/login");
        }
        sharedConfig.LANGUAGES.map(language => {
            this.checkedLanguagesForExport.push(language.language);
        });
        window.scrollTo(0, 0);
        this.getFeedbacks()
    }

    getFeedbacks() {
        let self = this;
        //Get all products
        fetch(config.BASE_URL + "feedbacks/all", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    self.feedbacks = json;
                    self.setState({});
                });

            } else {
                //console.log(response.status)
            }
        }).catch(
            error => {
                //console.log(error);
                if (error.status === 401) {
                    history.replace("/" + this.props.i18n.language + "/login");
                }
            }
        );
    }


    fetchCSV(feedback) {
        //Get all products
        fetch(config.BASE_URL + "feedbacks/csv/" + feedback.id , {
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({
                questionIds: toJS(this.chosenQuestionIds),
                start: this.start,
                end: this.end,
                languages: this.checkedLanguagesForExport
            })
        }).then(response => {
            response.blob().then((blob) => {
                showBlobFile(blob, feedback.version + "_" + feedback.title["de-DE"] + "_" + (new Date().toLocaleString().split(".").join("")));
            });
            this.start = "";
            this.end = "";
        }).catch(
            error => {
                //console.log(error);
                if (error.status === 401) {
                    history.replace("/" + this.props.i18n.language + "/login");
                }
            }
        );
    }

    deleteFeedbacks(feedback) {
        //Get all products
        fetch(config.BASE_URL + "feedbacks/" + feedback.id, {
            method: "DELETE",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
        }).then(response => {
            this.deleteModalOpen = false
            this.chosenFeedback = DEFAULT_FEEDBACK
            this.getFeedbacks()
        }).catch(
            error => {
                //console.log(error);
                if (error.status === 401) {
                    history.replace("/" + this.props.i18n.language + "/login");
                }
            }
        );
    }

    getFeedbackAmount(feedbacks) {
        let systematic = 0;
        let info = 0;
        let noReview = 0;

        for (let i = 0; i < feedbacks.length; i++) {
            switch (feedbacks[i].type) {
                case 0:
                    noReview += 1;
                    break;
                case  1:
                    systematic += 1;
                    break;
                case 2:
                    info += 1;
                    break;
            }
        }

        return [
            <Table.Cell>{systematic}</Table.Cell>,
            <Table.Cell>{info}</Table.Cell>,
            <Table.Cell>{noReview}</Table.Cell>
        ]
    }

    getFeedbackQuestions(feedback) {
        let questions = [];
        if (feedback === null) {
            return <div/>;
        }
        let {i18n} = this.props;

        for (let i = 0; i < feedback.questions.length; i++) {
            let feedbackQuestion = feedback.questions[i];
            questions.push(<Table.Row key={i}>
                <Table.Cell>
                    <Checkbox
                        onChange={() => {
                            if (this.chosenQuestionIds.includes(feedbackQuestion.id)) {
                                this.chosenQuestionIds.splice(this.chosenQuestionIds.indexOf(feedbackQuestion.id), 1);
                            } else {
                                this.chosenQuestionIds.push(feedbackQuestion.id)
                            }
                        }}
                        checked={this.chosenQuestionIds.includes(feedbackQuestion.id)}
                    />
                </Table.Cell>
                <Table.Cell><span
                    dangerouslySetInnerHTML={{__html: feedbackQuestion.title[i18n.language]}}/></Table.Cell>
            </Table.Row>)
        }
        return <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Ausgewählt</Table.HeaderCell>
                    <Table.HeaderCell>Titel</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {questions}</Table.Body></Table>
    }


    render() {
        let {i18n} = this.props;
        let showFeedbacks = this.feedbacks.map((feedback, index) =>
            <Table.Row key={index}>
                <Table.Cell>{feedback.version}</Table.Cell>
                <Table.Cell>{feedback.title[i18n.language]}</Table.Cell>
                {this.getFeedbackAmount(feedback.feedbacks)}
                <Table.Cell>
                    <Button icon
                            onClick={() => {
                                this.chosenFeedback = feedback;
                                for (let i = 0; i < feedback.questions.length; i++) {
                                    this.chosenQuestionIds.push(feedback.questions[i].id)
                                }
                                this.exportModalOpen = true;
                            }}
                            color={"orange"}>
                        <Icon name='file alternate outline'/>
                    </Button>
                </Table.Cell>
                <Table.Cell>
                    <Button icon
                            onClick={() => {
                                this.chosenFeedback = feedback;
                                this.deleteModalOpen = true;
                            }}
                            color={"orange"}>
                        <Icon name='trash alternate outline'/>
                    </Button>
                </Table.Cell>
            </Table.Row>);

        const languagesForExportCheckbox = sharedConfig.LANGUAGES.map(language => (
            <Form.Field key={language.language}>
                <Checkbox
                    label={language.label}
                    value={language.language}
                    disabled={false} //TODO: disable langs which not where answerde in this language
                    checked={this.checkedLanguagesForExport.includes(language.language)}
                    onChange={_ => {
                        if (this.checkedLanguagesForExport.includes(language.language)) {
                            if (this.checkedLanguagesForExport.length > 1) {
                                this.checkedLanguagesForExport.splice(this.checkedLanguagesForExport.indexOf(language.language), 1);
                            }
                        } else {
                            this.checkedLanguagesForExport.push(language.language);
                        }
                        this.setState({});

                        console.log("CHECKED: ", toJS(this.checkedLanguagesForExport))
                    }}
                />
            </Form.Field>));


        return (
            <Grid>
                <Modal
                    open={this.exportModalOpen}
                    style={{margin: "auto"}}
                    onClose={() => {
                        this.exportModalOpen = false;
                        this.chosenQuestionIds = [];
                        this.chosenFeedback = DEFAULT_FEEDBACK;
                    }}>
                    <Modal.Header>
                        Export
                    </Modal.Header>
                    <Modal.Content scrolling>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <Button
                                        onClick={() => {
                                            this.chosenQuestionIds = [];
                                        }}>
                                        Alle abwählen
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            this.chosenQuestionIds = [];
                                            if (this.chosenFeedback != null)
                                                for (let i = 0; i < this.chosenFeedback.questions.length; i++) {
                                                    this.chosenQuestionIds.push(this.chosenFeedback.questions[i].id)
                                                }
                                        }}>
                                        Alle auswählen
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    {languagesForExportCheckbox}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Form>
                                        <Form.Group widths={"equal"}>
                                            <Form.Field>
                                                <label>Start Datum</label>
                                                <DatePicker
                                                    selected={this.start}
                                                    onChange={date => this.start = date}
                                                    selectsStart
                                                    startDate={this.start}
                                                    endDate={this.end}
                                                    dateFormat="dd.MM.yyy"/>
                                            </Form.Field>
                                            <Form.Field>
                                                <label className={"text-block"}>End Datum</label>
                                                <DatePicker
                                                    selected={this.end}
                                                    onChange={date => this.end = date}
                                                    selectsEnd
                                                    endDate={this.end}
                                                    minDate={this.start}
                                                    dateFormat="dd.MM.yyy"/>
                                            </Form.Field>
                                        </Form.Group>
                                    </Form>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    {this.getFeedbackQuestions(this.chosenFeedback)}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button icon
                                onClick={() => this.fetchCSV(this.chosenFeedback)}
                                color={"orange"}>
                            Exportieren
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Modal
                    open={this.deleteModalOpen}
                    style={{margin: "auto"}}
                    onClose={() => {
                        this.deleteModalOpen = false;
                        this.chosenFeedback = DEFAULT_FEEDBACK;
                    }}>
                    <Modal.Header>
                        Löschen
                    </Modal.Header>
                    <Modal.Content>
                        <p>Rückmeldungen dieser Version wirklich löschen?</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button icon
                                onClick={() => this.deleteFeedbacks(this.chosenFeedback)}
                                color={"orange"}>
                            Löschen
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Grid.Row centered>
                    <Grid.Column computer={8} tablet={10} mobile={15}>
                        <Table celled basic={"very"}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Version</Table.HeaderCell>
                                    <Table.HeaderCell>Titel</Table.HeaderCell>
                                    <Table.HeaderCell>Systematik</Table.HeaderCell>
                                    <Table.HeaderCell>Info</Table.HeaderCell>
                                    <Table.HeaderCell>Ohne Review</Table.HeaderCell>
                                    <Table.HeaderCell>Exportieren</Table.HeaderCell>
                                    <Table.HeaderCell>Löschen</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {showFeedbacks}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}