import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import translationDE from '../locale/locale.de'
import translationEN from '../locale/locale.en'

let languageToSet = "de-DE";
if (typeof window !== "undefined") {
    languageToSet = window.location.pathname.substr(1, 5);
    if(!languageToSet.match(/[a-z]{2}-[A-Z]{2}/)){
        languageToSet = "de-DE";
    }
}
// the translations
// (tip move them in a JSON file and import them)
const resources = {
    /*
    "en-EN": {
        translation: translationEN
    },
     */
    "en-US": {
        translation: translationEN
    },
    "en-CA": {
        translation: translationEN
    },
    "de-DE": {
        translation: translationDE
    },
    "en-PK": {
        translation: translationEN
    },
    "en-IN": {
        translation: translationEN
    }
};


i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: [`${languageToSet}`],
        fallbackLng: 'de-DE',
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;