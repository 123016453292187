import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {observer} from "mobx-react";
import PropTypes from "prop-types";

@withTranslation()
@observer
export default class ProgressFlower extends Component {

    render() {
        let {percent} = this.props;

        return (
            <div>
                {percent > 0.9 ?
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102.953 269.079"
                         style={{enableBackground: "new 0 0 102.953 269.079", position: "absolute"}}>
                        <g>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}}
                                  d="M68.957,19.02c-0.928-0.04-0.721-1.215-0.727-2.178
		c-1.107-0.929-1.138,1.419-2.54,0.363c-1.211-2.688-2.563-3.606-6.17-4.718c-2.448-0.755-2.957-0.749-5.08-0.362
		c-4.158,0.753-11.273-6.156-5.443-7.623c2.263-0.569,9.428,1.929,11.613,3.267c0.709,0.435,1.687,1.831,2.54,2.541
		c2.218,1.847,2.979,2.129,3.993,4.355C67.767,7.506,57.396,8.2,56.617,1.237c0.686-0.041,0.251-1.2,1.09-1.089
		c1.081-0.633,2.508,0.97,3.99,1.089c0.228,0.983,1.518,0.903,1.453,2.177c2.442,0.635,5.605,3.876,6.533,6.896
		C70.487,12.933,70.584,16.803,68.957,19.02z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}}
                                  d="M71.497,24.463c-1.923,0.381-2.906-0.97-4.717-1.088
		c-4.12-0.268-8.379,2.479-11.977,0c-1.361-2.311-0.597-4.896,2.177-5.807C61.538,16.071,72.782,20.111,71.497,24.463z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}}
                                  d="M75.854,22.287c1.66,0.153,2.838,0.79,2.903,2.54
		c0.479,1.021,3.039,1.325,1.813,2.903C78.653,26.26,76.551,24.976,75.854,22.287z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}}
                                  d="M72.95,22.65c1.353-0.146,2.129,0.289,2.177,1.452
		C74.046,25.076,72.927,23.887,72.95,22.65z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}}
                                  d="M71.497,25.553c2.806,0.339,3.65,2.642,3.993,5.443
		c3.618,1.22,4.602,5.077,6.896,7.622c0.81-0.157,0.145-1.789,0.36-2.54c2.387-0.079,6.182,4.08,2.543,5.443
		c-0.608-0.603-0.29-2.131-0.363-3.267c-0.686,0.041-0.254,1.201-1.09,1.09c0.611,2.611,2.575,5.282,2.177,9.073
		c-0.133,1.262-2.212,7.271-3.627,7.622c-2.721,0.67-4.188-2.793-3.993-5.445c0.248-3.386,4.519-6.684,2.54-10.525
		c-1.24,0.815-1.51,2.605-3.267,2.903c-4.979-1.39-0.505-7.183-1.813-10.888c-0.496,2.289-1.967,3.599-3.63,4.718
		c0.664,0.424,1.648,0.53,1.813,1.452c-0.204,1.852-1.421,2.692-3.267,2.903c-1.093-1.137-0.591-2.706,0.363-3.628
		c-2.121,0.307-2.549-1.08-3.99-1.452C65.773,29.749,69.831,28.846,71.497,25.553z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}}
                                  d="M75.127,25.553c1.642-0.192,2.368,0.533,2.177,2.177
		C75.871,27.712,75.145,26.987,75.127,25.553z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M79.483,28.457c0.954,0.376,2.365,0.292,2.177,1.815
		C80.671,31.078,79.38,29.864,79.483,28.457z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M76.577,28.82c1.421,0.031,2.46,0.443,2.18,2.177
		C77.903,31.584,76.56,30.239,76.577,28.82z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M80.207,31.723c1.081,0.128,1.843,0.577,2.54,1.088
		c0.05,0.895,0.056,1.749-0.36,2.177C80.91,34.648,79.95,33.796,80.207,31.723z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M83.11,32.085c1.71-0.376,1.825,0.837,2.54,1.452
		c-0.187,0.537-0.535,0.916-0.724,1.452C83.408,34.932,83.562,33.206,83.11,32.085z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M68.594,41.158c-0.854-0.007-1.069,0.626-2.177,0.363
		C63.537,38.51,69.509,36.025,68.594,41.158z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M64.24,41.158c2.442,1.215,0.564,5.638-2.543,4.718
		C62.096,43.856,62.955,42.295,64.24,41.158z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M68.594,44.788c-0.159-0.324-0.319-0.65-0.727-0.727
		C68.375,37.897,74.459,44.181,68.594,44.788z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M75.49,43.336c-0.189,1.385-1.297,1.85-2.903,1.815
		c-0.162-0.324-0.319-0.65-0.727-0.727C71.497,41.594,75.059,40.601,75.49,43.336z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M86.014,43.336c0.012-0.353,0.446-0.282,0.727-0.363
		c1.185,0.753,3.582,1.89,4.72,3.63c1.408,2.163,2.543,6.254,0,7.985C86.731,53.75,89.133,45.783,86.014,43.336z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M67.504,46.603c1.952-0.236,3.821-1.633,5.81-0.727c0,0.605,0,1.209,0,1.815
		c-3.544,0.304-6.459,0.261-8.35-1.088C64.76,43.865,68.381,42.4,67.504,46.603z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M90.37,44.062c0.009-0.352,0.443-0.281,0.727-0.362
		c6.116,1.974,12.821,6.868,10.887,14.517c-5.532,1.347-7.029-4.29-7.62-9.073C93.253,47.228,91.273,46.184,90.37,44.062z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                        </g>
                    </svg>

                    : null
                }

                {percent > 0.8 ?
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102.953 269.079"
                         style={{enableBackground: "new 0 0 102.953 269.079", position: "absolute"}}>
                        <g>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#66BB6A"}} d="M62.424,47.328c1.096,0.428,1.246,1.8,0.727,2.903
		c1.56-0.012,1.217-1.927,3.267-1.452c0.109,2.891-2.295,3.271-3.267,5.082c0.177,5.985-1.816,9.798-1.453,15.968
		c-2.829-0.882-2.07-6.086-1.813-9.435c0.222-2.89,0.998-5.649,1.453-7.26c0.195-1.285-1.288-0.892-1.09-2.177
		C60.466,49.24,61.641,48.483,62.424,47.328z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                        </g>
                    </svg>
                    : null}


                {percent > 0.7 ?
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102.953 269.079"
                         style={{enableBackground: "new 0 0 102.953 269.079", position: "absolute"}}>
                        <g>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#66BB6A"}} d="M59.521,141.327c1.237-4.569,5.089-6.524,6.17-11.25
		c1.657-0.326,2.059,0.604,3.267,0.726c3.485-1.938,4.469-9.911,10.163-7.259c3.322-2.073,6.604-5.724,11.613-3.992
		c0.044,0.65-0.446,0.765-0.363,1.452c-1.252,0.608-1.606,0.009-3.267,0.362c-3.684,2.077-7.266,4.058-10.526,6.534
		c-4.708,3.573-8.707,8.073-13.79,11.251C61.815,139.884,61.029,142.286,59.521,141.327z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#66BB6A"}} d="M96.54,121.365c0.216,4.89-5.715,3.925-9.073,5.445
		c-1.104,0.5-1.389,1.341-2.54,1.452c-0.511,0.94-0.505,2.399-1.09,3.267c-3.482,1.232-6.965,2.472-9.8,4.354
		c0.216,1.309,0.614,2.434,0,3.629c-3.436,1.402-7.544,2.134-10.523,3.993c-1.386,1.639-2.165,3.884-3.267,5.807
		c-2.105-1.787,0.906-3.97,0.727-6.17c1.76-0.53,2.46-2.825,3.99-4.355c2.75-2.75,7.839-5.744,12.34-9.073
		c4.436-3.28,9.2-5.917,12.703-8.348C91.572,120.28,94.703,120.874,96.54,121.365z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#66BB6A"}} d="M96.177,125.358c6.746,0.905,7.957,10.178,5.807,15.968
		c-0.484,0-0.969,0-1.45,0c-2.47-1.523-2.363-5.622-3.993-7.984c-1.364,0.209-0.756,2.392-2.177,2.541
		c-2.721-1.418-1.84-6.678-1.816-9.073c1.205,0.3,2.241-0.013,3.63,0.725c0.706,2.44,2.555,3.735,3.267,6.17
		C99.824,130.901,96.726,128.442,96.177,125.358z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                        </g>
                    </svg>
                    : null}

                {percent > 0.6 ?
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102.953 269.079"
                         style={{enableBackground: "new 0 0 102.953 269.079", position: "absolute"}}>
                        <g>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#66BB6A"}} d="M57.344,121.365c0.422-0.059,0.644,0.082,0.727,0.363
		c0.854,4.745,0.812,9.864,0.724,14.88c-0.089,5.201-0.384,10.633-0.363,15.97c0.083,18.623-1.146,37.495-1.087,54.076
		c0.012,3.582-0.181,7.318-0.363,10.888c-0.198,3.849,0,7.663-0.363,11.25c-0.363,3.584-1.56,7.156-1.813,10.889
		c-0.054,0.751,0.452,1.534,0.363,2.178c-0.062,0.432-0.659,0.968-0.727,1.452c-0.674,4.825-1.507,9.945-4.72,13.428
		c8.695-41.117,3.429-90.127,7.26-134.284C56.877,121.869,57.323,121.829,57.344,121.365z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                        </g>
                    </svg>
                    : null}

                {percent > 0.5 ?
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102.953 269.079"
                         style={{enableBackground: "new 0 0 102.953 269.079", position: "absolute"}}>
                        <g>

                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M44.277,64.023c1.323,9.718-4.554,15.252-3.629,25.768
		c2.147-7.431,3.109-17.129,8.349-21.774c1.787-1.585,5.225-3.314,8.347-2.178c0.683,2.461-1.704,4.438-2.177,6.896
		c-2.354,0.79-3.642,2.647-5.446,3.992c0.263,1.109-0.369,1.321-0.363,2.177c-4.014,3.002-4.451,9.583-7.62,13.43
		c-0.759-0.331-1.107-1.072-1.09-2.179c-0.74,0.47-0.66,1.759-2.177,1.452c0.265-7.576-3.135-16.033-1.09-22.501
		c0.672-2.125,2.682-3.317,3.267-5.443C42.402,62.986,42.663,63.779,44.277,64.023z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M35.568,92.694c-1.032-9.734-8.191-13.342-7.622-24.678
		c0.292-0.313,0.994-0.216,1.088-0.727c2.234,0.427,2.917,2.407,4.355,3.63c1.038,4.551,3.288,10.479,3.63,16.331
		C37.126,89.067,37.78,92.209,35.568,92.694z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M33.39,94.146c-1.831-1.436-1.735-3.9-3.63-5.443
		c-1.126-0.919-2.823-1.271-3.992-2.179c-3.491-2.714-6.313-6.561-7.622-11.976c0.422,0.059,0.646-0.08,0.727-0.362
		c1.123-0.674,2.363,1.116,3.992,1.088C26.25,81.259,35.457,85.108,33.39,94.146z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M62.424,75.638c0.153,2.33-0.922,3.432-2.54,3.992
		c-1.539,4.025-5.766,5.363-8.347,8.347c-0.464,0.505-1.713,0.223-2.18,0.727c-1.981,1.682-3.804,5.337-6.895,4.718
		c4.614-4.971,5.707-15.12,12.704-17.783C56.906,74.975,60.675,74.145,62.424,75.638z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M70.771,84.348c0.7,2.639-2.387,3.039-4.354,3.992
		c-1.74,0.841-3.659,2.549-5.443,2.903c-1.896,0.376-3.961-0.282-5.807,0c-3.904,0.598-6.472,3.318-9.8,3.63
		c1.347-2.26,4.238-3.172,6.533-5.082c4.362-3.631,6.595-7.559,14.153-7.258C68.071,82.612,69.453,82.933,70.771,84.348z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M32.665,96.687c-1.708,0.877-2.501-1.125-3.63-1.814
		c-1.097-0.672-2.543-1.248-3.992-1.815c-5.916-2.317-12.867-2.711-14.881-8.709c0.448-0.158,0.746-0.465,0.727-1.088
		c3.977-0.099,9.19,1.404,11.977,2.903c0.666,0.357,1.072,1.286,1.815,1.813C27.473,89.963,32.425,92.653,32.665,96.687z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M73.313,94.873c-0.029,1.422-0.641,2.264-1.09,3.267
		c-5.556,2.229-11.521-0.731-17.42-1.09c-2.652-0.16-5.198,0.838-7.623-0.362C53.699,94.106,66.44,89.306,73.313,94.873z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                        </g>
                    </svg>
                    : null}

                {percent > 0.4 ?
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102.953 269.079"
                         style={{enableBackground: "new 0 0 102.953 269.079", position: "absolute"}}>
                        <g>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M30.486,99.59c-6.36-1.017-16.545,2.401-20.687-3.267
		C13.095,90.807,29.856,93.854,30.486,99.59z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M36.656,93.42c0.651-0.045,0.765,0.445,1.452,0.363
		c0.152,1.241-0.109,2.068-1.452,1.813C36.423,94.8,36.178,94.393,36.656,93.42z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M42.826,95.597c0.651-0.045,0.765,0.445,1.451,0.363
		c0.071,1.04,0.044,1.98-1.088,1.815C43.292,96.671,42.026,96.364,42.826,95.597z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M34.116,95.96c0.923,0.044,1.408,0.529,1.452,1.452
		c-0.691,0.156-0.913,0.782-1.815,0.727C33.704,97.244,33.698,96.391,34.116,95.96z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M39.196,96.324c1.043-0.075,1.845,0.092,2.178,0.726
		c0.052,1.141-0.93,1.248-1.815,1.451C39.142,98.072,39.149,97.219,39.196,96.324z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M36.656,97.049c0.477,0.371,1.338,0.355,1.815,0.727
		c0.318,1.165-0.942,0.751-1.09,1.451C36.454,99.188,36.662,98.012,36.656,97.049z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M45.004,97.776c1.063,0.025,1.347,0.83,1.813,1.451
		c0.018,1.105-0.765,1.412-1.087,2.179c-1.947-0.696-1.592-0.432-3.268,0.361c0-0.725,0-1.451,0-2.177
		C44.289,100.332,44.785,99.474,45.004,97.776z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M32.301,99.59c2.366-0.472,2.735,2.038,0.363,1.815
		C32.812,100.53,32.001,100.615,32.301,99.59z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M39.56,99.59c0.901-0.054,1.506,0.188,1.815,0.726
		c-0.145,1.072-1.795,1.728-2.542,1.09C38.778,100.502,39.405,100.282,39.56,99.59z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                        </g>
                    </svg>
                    : null}

                {percent > 0.3 ?
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102.953 269.079"
                         style={{enableBackground: "new 0 0 102.953 269.079", position: "absolute"}}>
                        <g>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M48.271,99.59c7.569-0.333,24.766,2.295,18.873,10.162
		c-7.874,0.242-11.873-6.508-18.146-9.073C48.548,100.521,48.25,100.214,48.271,99.59z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M35.93,100.316c0.68,0.41,1.749,0.43,1.452,1.814
		c-0.874-0.149-0.789,0.663-1.813,0.363C35.52,101.599,35.512,100.746,35.93,100.316z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M31.211,103.583c-8.327-0.092-14.082,6.926-21.775,3.991
		c-0.94-4.018,6.712-6.326,11.977-6.531C24.919,100.905,29.693,101.253,31.211,103.583z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M44.277,102.857c-0.018,1.433-0.744,2.159-2.178,2.177
		c0.066-0.309-0.142-0.342-0.362-0.363C41.605,103.141,43.12,101.964,44.277,102.857z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M46.454,102.857c0.059-0.425,0.661-0.307,1.09-0.363
		c5.334,3.62,10.677,7.228,15.243,11.613c-0.251,1.926-0.366,3.989-1.813,4.718C52.984,116.652,51.785,107.69,46.454,102.857z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M41.375,102.857c-0.013,1.196-1.032,1.387-2.178,1.452
		c0.059-0.423-0.08-0.647-0.363-0.727C38.885,102.544,40.348,102.919,41.375,102.857z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                        </g>
                    </svg>
                    : null}

                {percent > 0.2 ?
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102.953 269.079"
                         style={{enableBackground: "new 0 0 102.953 269.079", position: "absolute"}}>
                        <g>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M36.656,103.583c0.677,0.05,1.212,0.239,1.452,0.727
		c0.102,1.162-1.589,1.318-2.178,0.725C36.119,104.497,36.467,104.12,36.656,103.583z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M43.914,104.67c0.363,0,0.727,0,1.09,0
		c2.956,2.939,7.582,8.586,9.073,13.792c0.543,1.899,0.807,4.364,0,6.17c-4.146,1.047-6.017-3.828-7.26-6.895
		c-1.477-3.649-2.021-8.323-2.903-11.977C43.914,105.397,43.914,105.034,43.914,104.67z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M31.575,105.76c0.102,1.312-1.589,0.83-2.177,1.452
		c-3.553,4.069-6.873,8.371-9.8,13.065c-2.351,0.848-4.64-0.069-5.443-1.815c0.937-3.497,3.38-7.007,6.17-9.073
		C23.154,107.293,27.397,105.292,31.575,105.76z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                        </g>
                    </svg>
                    : null}

                {percent > 0.1 ?
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102.953 269.079"
                         style={{enableBackground: "new 0 0 102.953 269.079", position: "absolute"}}>
                        <g>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M41.375,106.122c0.309-0.066,0.343,0.142,0.363,0.363
		c0.363,3.974,3.174,8.783,3.993,13.066c0.617,3.243,1.275,7.096-0.727,9.435c-5.488,0.23-5.824-9.532-5.444-15.243
		C39.762,110.7,40.728,107.942,41.375,106.122z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M33.753,106.849c0.353,0.011,0.282,0.445,0.363,0.725
		c0.282,2.41-2.02,3.627-2.905,5.808c-1.924,4.75-1.75,10.829-5.08,14.517c-4.157,1.269-5.022-3.723-3.993-6.896
		C23.488,116.848,32.174,110.926,33.753,106.849z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                            <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#F39A38"}} d="M37.381,106.849c0.422-0.059,0.647,0.08,0.727,0.363
		c0.468,2.378-0.961,4.18-1.088,5.807c-0.244,3.082,0.964,8.763,0.725,12.34c-0.198,2.959-1.102,7.342-3.267,7.622
		c-2.822,0.363-4.312-4.735-4.355-6.533c-0.026-1.106,0.39-3.157,0.727-4.355C32.372,116.674,35.977,111.356,37.381,106.849z">
                                <animate attributeType="CSS" attributeName="opacity"
                                         from="0" to="1" dur="1s" repeatCount="0"/>
                            </path>
                        </g>
                    </svg>
                    : null}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102.953 269.079"
                     style={{enableBackground: "new 0 0 102.953 269.079", position: "absolute"}}>
                    <g>
                        <path style={{fillRule: "evenodd", clipRule: "evenodd", fill: "#66BB6A"}}
                              d="M45.73,129.35c1.355-0.388,1.033,0.901,2.177,0.727
		c2.215,9.846,2.918,21.09,2.54,33.026c-0.192,6.074-0.564,12.381-0.363,18.51c0.431,13.098,2.655,25.221,2.18,38.47
		c-0.413,11.497-2.759,21.326-4.72,32.663c-0.98,5.671-0.759,11.238-5.081,13.43c0.316,2.13-0.938,2.69-2.54,2.903
		c2.685-8.015,3.76-16.466,5.808-24.68c0.479-1.926,0.706-3.506,1.087-6.533c0.39-3.089,1.843-5.922,2.18-9.073
		c0.597-5.597-0.721-11.403,0.36-17.783c0.171-1.493-0.91,0.285-1.45-0.725c0.083-2.504-1.412-3.428-1.813-5.445
		c-2.281-0.262-3.807-1.275-4.719-2.903c-0.573-1.421,0.942-0.752,1.088-1.451c-0.756-0.454-1.53-0.89-2.178-1.452
		c-1.766-0.438-0.983,1.678-2.903,1.089c-1.932-0.003-2.504-1.366-3.628-2.177c-0.525-1.374,0.997-0.697,0.725-1.815
		c-1.924-0.517-3.829,0.689-5.443,0.363c-1.741-0.353-2.601-2.381-4.355-2.903c-0.913-0.273-2.163,0.236-2.903,0
		c-1.938-0.622-3.566-2.289-3.993-4.356c-2.771-0.251-3.724-2.323-5.807-3.265c-0.821,1.477-2.516,2.08-2.903,3.991
		c-3.81,0.753-7.663,0.435-9.073-2.178c2.379-2.173,5.229-3.998,9.437-2.54c0.994-0.328-0.708-1.337,0.725-1.452
		c5.345-0.39,8.991,1.193,13.066,2.903c-0.401-1.896-3.081-1.517-2.903-3.991c6.73-1.456,7.797,6.026,14.88,4.354
		c2.63,2.297,3.806,5.988,8.347,5.443c3.709,3.187,4.693,9.102,5.446,15.243c-0.222-18.521-1.923-36.954-1.816-54.803
		C47.222,146.009,46.354,137.163,45.73,129.35z M30.486,191.775c2.717,0.186,4.779,1.027,5.807,2.903
		c3.905-0.031,6.631,3.043,8.711,3.628c-2.389-3.056-6.274-4.613-10.526-5.807c-1.019-2.004-3.117-2.93-5.807-3.267
		c-0.662-0.525-2.202-2.45-2.903-0.725C28.295,188.644,29.312,190.286,30.486,191.775z">
                            <animate attributeType="CSS" attributeName="opacity"
                                     from="0" to="1" dur="1s" repeatCount="0"/>
                        </path>
                    </g>
                </svg>
            </div>
        );
    }
}

ProgressFlower.propTypes = {
    percent: PropTypes.FLOAT,
};

ProgressFlower.defaultProps = {
    percent: 0
};