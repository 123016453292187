import React from "react";
import PropTypes from "prop-types";
import {inject, observer} from "mobx-react";
import {observable, toJS} from "mobx";
import {withTranslation} from "react-i18next";
import sharedConfig from "../../../shared/sharedConfig";
import userStore from "../stores/userStore"
import history from "../helper/browserHistory";
import config from "../../config/main.config";

import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";

if (typeof document !== "undefined") {
    require('react-draft-wysiwyg/dist/react-draft-wysiwyg.css');
}

@inject("stores")
@withTranslation()
@observer
export default class EditTextButtonComponent extends React.Component {
    @observable editState = false;

    handleLanguageChange(language) {
        const {textStore} = this.props.stores;
        let id = this.props.id;

        let textToEdit = {};
        if (typeof textStore.texts[id] !== "undefined") {
            if (typeof textStore.texts[id][language] !== "undefined") {
                textToEdit = toJS(textStore.texts[id][language]);
            }
        }

        this.setState({
            ...this.state,
            language: language,
            htmlText: textToEdit.content,
            description: textToEdit.description,
            title: ''
        });
    }


    // TODO: Evtl den Text in der Komponente laden und nicht am anfang alle laden
    constructor(props) {
        super(props);

        this.changeText = this.changeText.bind(this);

        let language = 'de-DE';
        if (this.props.i18n && typeof this.props.i18n.language !== "undefined") {
            language = this.props.i18n.language;
        }

        this.state = {
            htmlText: '',
            title: '',
            description: '',
            language: language
        }
    }

    changeText(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            ...this.state,
            [name]: value
        });
    }

    handleChange = (e, {value}) => this.setState({
        ...this.state,
        language: value,
        htmlText: '',
        description: '',
        title: ''
    })


    render() {
        let {id, style, cssClass, arrow, color} = this.props;
        const {textStore} = this.props.stores;
        let contentToShow = "";
        const displayType = userStore.isAdmin ? "admin" : "user";
        let textToShow = {};


        if (displayType === "admin") {
            if (typeof textStore.texts[id] !== "undefined") {
                if (typeof textStore.texts[id][this.state.language] !== "undefined") {
                    textToShow = toJS(textStore.texts[id][this.state.language]);
                } else {
                    textToShow = {};
                }
            }
            if (this.editState) {
                if (style === null) {
                    style = {
                        "zIndex": 1,
                        "position": "relative",
                        "backgroundColor": "white",
                        "overflow": "auto"
                    };
                } else {
                    // need to copy this because otherwise I get "not extendable error"
                    let customStyle = Object.assign({}, style);
                    customStyle.zIndex = 1;
                    customStyle.position = "relative";
                    customStyle.backgroundColor = "white";
                    customStyle.overflow = "auto";
                    style = customStyle;
                }


                contentToShow = <div style={style}>
                    <Form>
                        <Form.Field>
                            <label>Button Text</label>
                            <Input value={this.state.htmlText}
                                   name='htmlText'
                                   onChange={this.changeText}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Button Link</label>
                            <Input value={this.state.description}
                                   name='description'
                                   onChange={this.changeText}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Button.Group>
                                <Button color="green" onClick={() => {
                                    let textToEdit = {};
                                    if (this.state.htmlText !== '') {
                                        textToEdit.content = this.state.htmlText;
                                    } else {
                                        textToEdit.content = "";
                                    }

                                    if (this.state.title !== '') {
                                        textToEdit.title = this.state.title;
                                    } else {
                                        textToEdit.title = "";
                                    }

                                    if (this.state.description !== '') {
                                        textToEdit.description = this.state.description;
                                    } else {
                                        textToEdit.description = "";
                                    }

                                    if (typeof textStore.texts[id] === "undefined" || typeof textStore.texts[id][this.state.language] === "undefined") {
                                        textStore.createText(id, textToEdit, this.state.language).then(isSuccess => {
                                            if (isSuccess) {
                                                this.editState = false;
                                            }
                                        }).catch(() => {

                                        });
                                    } else {
                                        textStore.updateText(id, textToEdit, this.state.language).then(isSuccess => {
                                            if (isSuccess) {
                                                this.editState = false;
                                            }
                                        }).catch(() => {

                                        });
                                    }
                                }}>
                                    {typeof textStore.texts[id] === "undefined" || typeof textStore.texts[id][this.state.language] === "undefined" ? "Speichern" : "Bearbeiten"}
                                </Button>
                                <Button color="red" onClick={() => this.editState = false}>Abbrechen</Button>
                            </Button.Group>
                        </Form.Field>
                    </Form>

                </div>
            } else {
                const options = sharedConfig.LANGUAGES.map(language => (
                    {
                        key: language.label,
                        text: language.label,
                        value: language.language
                    }
                ));

                contentToShow = <div style={style}>
                    <Form>
                        <Form.Field>
                            <label>Sprache</label>
                            <Dropdown
                                fluid
                                selection
                                value={this.state.language}
                                options={options}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                    </Form>
                    <div id={id} dangerouslySetInnerHTML={{__html: textToShow.content}}/>
                    <div id={id} dangerouslySetInnerHTML={{__html: textToShow.description}}/>
                    <br/>
                    <Button color="orange" icon="edit outline"
                            content={"Button bearbeiten"}
                            onClick={() => {

                                this.setState({
                                    ...this.state,
                                    htmlText: textToShow.content,
                                    description: textToShow.description,
                                })
                                this.editState = true
                            }}/>

                    <Button color="orange" icon="edit outline"
                            content={"Zum Link"}
                            onClick={() => history.push("/"+this.props.i18n.language+textToShow.description)}/>
                </div>
            }

        } else {
            let language = "de-DE";

            if (this.props.i18n) {
                language = this.props.i18n.language;
            }

            if (typeof textStore.texts[id] !== "undefined") {
                if (typeof textStore.texts[id][language] !== "undefined") {
                    textToShow = toJS(textStore.texts[id][language]);
                } else {
                    textToShow = {};
                }
            }
            let contentWithoutSpace = "";
            if (textToShow.hasOwnProperty("content") && textToShow.content.length > 0) {
                contentWithoutSpace = textToShow.content.replace(" ", "");
            }

            let arrowSrc = config.PUBLIC_URL + "images/icons/arrow-right-orange.svg";
            if (arrow && color.length > 0) {
                switch (color) {
                    case "blue":
                        arrowSrc = config.PUBLIC_URL + "images/icons/arrow-right-blue.svg";
                        break;
                    case "red":
                        arrowSrc = config.PUBLIC_URL + "images/icons/arrow-right-red.svg";
                        break;
                }
            }

            contentToShow = contentWithoutSpace.length === 0 ?
                <div/>
                :
                <Button
                    style={{
                        backgroundImage: " url('" + config.PUBLIC_URL + "images/buttonwhite.png" + "')"
                    }}
                    onClick={() => {
                        textToShow.description.startsWith("http") ?
                            window.open(textToShow.description, "_blank") :
                            history.push("/"+this.props.i18n.language+textToShow.description)
                    }}
                    className={typeof textToShow.content != "undefined" ? cssClass : "hide-div"}>
                    {typeof textToShow.content != "undefined" ? textToShow.content.toUpperCase() : ""}
                    {arrow ?
                        <Image
                            alt={"Bild zum Link"}
                            spaced
                            style={{height: "14px", width: "20px", marginBottom: "1px"}}
                            src={arrowSrc}/>
                        :
                        null
                    }
                </Button>;
        }
        return (
            contentToShow
        );
    }
}

EditTextButtonComponent.propTypes = {
    id: PropTypes.string,
    style: PropTypes.object,
    cssClass: PropTypes.string,
    arrow: PropTypes.bool,
    color: PropTypes.string
};

EditTextButtonComponent.defaultProps = {
    id: Math.random().toString(),
    style: null,
    cssClass: "title-button",
    arrow: false,
    color: ""
};

