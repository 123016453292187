import {action, computed, observable} from 'mobx';
import config from "../../config/main.config";
import {
    getTokenFromLocalStorage,
    getUserFromLocalStorage,
    removeTokenFromStorage,
    setTokenLocalStorage,
    setUserToLocalStorage
} from "../helper/util";
import history from "../helper/browserHistory"

class UserStore {
    @observable user = null;
    @observable users = [];
    @observable loading = false;
    @observable error = false;

    @observable languageToSet = "de-DE";
    @computed get Currentlanguage(){
        if (typeof window !== "undefined") {
            this.languageToSet = window.location.pathname.substr(1, 5);
            if(!this.languageToSet.match(/[a-z]{2}-[A-Z]{2}/)){
                this.languageToSet = "de-DE";
    }
}
}

    @computed get userFromServer() {
        if (this.user === null) {
            if (getUserFromLocalStorage() !== null && typeof getUserFromLocalStorage() !== "undefined") {
                this.user = getUserFromLocalStorage();
            }
        }
        return this.user;
    }

    @computed get isAdmin() {
        if (this.user === null) {
            if (getUserFromLocalStorage() !== null && typeof getUserFromLocalStorage() !== "undefined") {
                this.user = getUserFromLocalStorage();
            }
        }
        if (this.user != null && this.user.hasOwnProperty("roles")) {
            return this.user.roles.includes("feedback_Admin") || this.user.roles.includes("Admin");
        } else {
            return false;
        }

    }

    @computed get isReviewer() {
        if (this.user === null) {
            if (getUserFromLocalStorage() !== null && typeof getUserFromLocalStorage() !== "undefined") {
                this.user = getUserFromLocalStorage();
            }
        }
        if (this.user != null && this.user.hasOwnProperty("roles")) {
            return this.user.roles.includes("feedback_Reviewer");
        } else {
            return false;
        }

    }

    @action getUsers() {
        fetch(config.BASE_URL + "users/getAll", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.users = json;
                });

            } else {
                //TODO do something
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                if (error.status === 401) {
                    history.replace("/"+this.languageToSet+"/admin-login");
                }
            }
        );
    }

    @action authenticateUser(userToAuthenticate) {
        return new Promise(resolve => {
            const token = getTokenFromLocalStorage();
            if (userToAuthenticate === null) {
                alert("Bitte melden Sie sich erneut an.")
                resolve(false);
            } else {
                this.loading = true;
                return fetch(config.BASE_URL + "users/authenticate", {
                    method: "POST",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": "Bearer " + token
                    },
                    body: JSON.stringify(userToAuthenticate)
                })
                    .then(response => {
                        this.loading = false;
                        if (response.status >= 200 && response.status < 300) {
                            response.json().then(json => {
                                setTokenLocalStorage(json.token);
                                setUserToLocalStorage(json);
                                this.user = json;
                                resolve(true);
                            });

                        } else {
                            //TODO: Alert?
                            removeTokenFromStorage();
                            this.error = true;
                            this.user = null;
                            resolve(false);
                        }
                    })
                    .catch(
                        error => {
                            //TODO: Alert?
                            removeTokenFromStorage();
                            this.loading = false;
                            this.error = true;
                            this.user = null;
                            resolve(false);
                            throw error;
                        }
                    );
            }
        });
    }

    @action createUser(userToRegister) {
        const token = getTokenFromLocalStorage();
        if (userToRegister === null) {
            alert("Bitte melden Sie sich erneut an.")
        } else {
            this.loading = true;
            return fetch(config.BASE_URL + "users/create", {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": "Bearer " + token
                },
                body: JSON.stringify(userToRegister)
            })
                .then(response => {
                    this.loading = false;
                    if (response.status >= 200 && response.status < 300) {
                        response.json().then(json => {
                            this.getUsers();
                        });

                    } else {
                        //TODO: Alert?
                        removeTokenFromStorage();
                        this.error = true;
                        this.user = null;
                    }
                })
                .catch(
                    error => {
                        //TODO: Alert?
                        removeTokenFromStorage();
                        this.loading = false;
                        this.error = true;
                        this.user = null;
                        throw error;
                    }
                );
        }

    }

    @action editUser(userToEdit) {
        const token = getTokenFromLocalStorage();
        if (userToEdit === null) {
            alert("Bitte melden Sie sich erneut an.")
        } else {
            this.loading = true;
            return fetch(config.BASE_URL + "users", {
                method: "PUT",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": "Bearer " + token
                },
                body: JSON.stringify(userToEdit)
            })
                .then(response => {
                    this.loading = false;
                    if (response.status >= 200 && response.status < 300) {
                        response.json().then(json => {
                            this.getUsers();
                        });

                    } else {
                        //TODO: Alert?
                        removeTokenFromStorage();
                        this.error = true;
                        this.user = null;
                    }
                })
                .catch(
                    error => {
                        //TODO: Alert?
                        removeTokenFromStorage();
                        this.loading = false;
                        this.error = true;
                        this.user = null;
                        throw error;
                    }
                );
        }

    }

    @action deleteUser(userId) {
        const token = getTokenFromLocalStorage();
        if (userId === null) {
            alert("Bitte melden Sie sich erneut an.")
        } else {
            this.loading = true;
            return fetch(config.BASE_URL + "users/" + userId, {
                method: "DELETE",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": "Bearer " + token
                }
            })
                .then(response => {
                    this.loading = false;
                    if (response.status >= 200 && response.status < 300) {
                        response.json().then(json => {
                            this.getUsers();

                        });

                    } else {
                        //TODO: Alert?
                        removeTokenFromStorage();
                        this.error = true;
                        this.user = null;
                    }
                })
                .catch(
                    error => {
                        //TODO: Alert?
                        removeTokenFromStorage();
                        this.loading = false;
                        this.error = true;
                        this.user = null;
                        throw error;
                    }
                );
        }

    }
}

const store = new UserStore();

export default store;