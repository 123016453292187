import React from "react";
import {observer} from "mobx-react";
import {removeTokenFromStorage} from "../helper/util";
import userStore from "../stores/userStore";
import {observable} from "mobx";
import history from "../helper/browserHistory";
import {HamburgerSqueeze} from 'react-animated-burgers'

import {withTranslation} from "react-i18next";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {Icon} from "semantic-ui-react";
import sharedConfig from "../../../shared/sharedConfig";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import config from "../../config/main.config";

@withTranslation()
@observer
export default class NavigationBar extends React.Component {
    @observable
    showMenu = false;

    @observable
    activeElement = "none";

    setActive() {
        if (typeof window !== "undefined") {
            this.activeElement = window.location.pathname.substr(1)
        }
        this.setState({});
    }

    componentDidMount() {
        this.setActive();
        history.listen(this.setActive.bind(this));
    }

    getCurrentLanguage() {
        let currentLanguage = "";

        for (let i = 0; i < sharedConfig.LANGUAGES.length; i++) {
            let language = sharedConfig.LANGUAGES[i];
            if (this.props.i18n.language === language.language) {
                currentLanguage = language.label;
                break;
            }
        }
        return currentLanguage;
    }

    getDropdownItems() {
        let langauges = [];

        for (let i = 0; i < sharedConfig.LANGUAGES.length; i++) {
            let language = sharedConfig.LANGUAGES[i];
            langauges.push(
                <Dropdown.Item
                    key={"language" + i}>
                    <p className={this.props.i18n.language === language.language ? "active-language" : "inactive-language"}
                       onClick={() => {
                           let newURL = "/" + language.language + removeLanguageFromString(window.location.pathname) + window.location.search;
                           this.props.i18n.changeLanguage(language.language)
                           history.replace(newURL);
                       }}>
                        {language.label}
                    </p>
                </Dropdown.Item>
            )
        }
        return langauges;
    }


    render() {
        const {i18n} = this.props;

        return (
            <Grid>
                <Responsive as={Grid.Row} maxWidth={800} className={"mobile-burger-row"}>
                    <Grid.Column style={{"marginLeft": "5px"}}>
                        <HamburgerSqueeze barColor="white" isActive={this.showMenu}
                                          onClick={() => this.showMenu = !this.showMenu}/>
                    </Grid.Column>
                </Responsive>
                <Responsive as={Grid.Row} maxWidth={800} className={this.showMenu ? "no-top-padding" : "hide-div"}>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Segment className={"navigation-menu-segment"} style={{"borderRadius": "0px"}}>
                            <Menu key="menu" pointing secondary stackable className={"navigation-menu"}
                                  widths={4}>
                                {/*
                                <Menu.Item
                                    active={this.activeElement === ""}
                                    onClick={() => {
                                        history.push("/"+this.props.i18n.language+"/");
                                        this.showMenu = false;
                                        this.setState({});
                                    }}
                                    key="landing">
                                    {i18n.t("titles.landing").toUpperCase()}
                                </Menu.Item>
                                */}
                                <Menu.Item
                                    active={this.activeElement === "/overview"}
                                    onClick={() => {
                                        history.push("/" + this.props.i18n.language + "/overview");
                                        this.showMenu = false;
                                        this.setState({});
                                    }}
                                    key="home">
                                    {i18n.t('titles.own_overview').toUpperCase()}
                                </Menu.Item>
                                {userStore.userFromServer === null && true == false &&
                                [
                                    <Menu.Item
                                        active={this.activeElement === "register"}
                                        onClick={() => {
                                            history.push("/" + this.props.i18n.language + "/register");
                                            this.showMenu = false;
                                            this.setState({});
                                        }}
                                        key="register">
                                        {i18n.t("login_page.register").toUpperCase()}
                                    </Menu.Item>,
                                    <Menu.Item
                                        active={this.activeElement === "login"}
                                        onClick={() => {
                                            history.push("/" + this.props.i18n.language + "/login");
                                            this.showMenu = false;
                                            this.setState({});
                                        }}
                                        key="login">
                                        {i18n.t("login_page.log_in").toUpperCase()}
                                    </Menu.Item>
                                ]
                                }
                                {userStore.isAdmin ?
                                    <Menu.Item
                                        active={this.activeElement === "questionnaires-overview"}
                                        onClick={() => {
                                            history.push("/" + this.props.i18n.language + "/questionnaires-overview");
                                            this.showMenu = false;
                                            this.setState({});
                                        }}
                                        key="quest_over">
                                        {i18n.t('titles.questionnaires_overview')}
                                    </Menu.Item>
                                    :
                                    null
                                }
                                {userStore.isAdmin ?
                                    <Menu.Item
                                        active={this.activeElement === "products"}
                                        onClick={() => {
                                            history.push("/" + this.props.i18n.language + "/products");
                                            this.showMenu = false;
                                            this.setState({});
                                        }}
                                        key="products">
                                        {i18n.t('titles.products')}
                                    </Menu.Item>
                                    :
                                    null
                                }
                                {userStore.isReviewer || userStore.isAdmin ?
                                    <Menu.Item
                                        active={this.activeElement === "review-overview"}
                                        onClick={() => {
                                            history.push("/" + this.props.i18n.language + "/review-overview");
                                            this.showMenu = false;
                                            this.setState({});
                                        }}
                                        key="rev_over">
                                        {i18n.t('titles.review_overview')}
                                    </Menu.Item>
                                    :
                                    null
                                }
                                {userStore.isAdmin ?
                                    <Menu.Item
                                        active={this.activeElement === "feedback-overview"}
                                        onClick={() => {
                                            history.push("/" + this.props.i18n.language + "/feedback-overview");

                                            this.showMenu = false;
                                            this.setState({});
                                        }}
                                        key="feedback_overview">
                                        {i18n.t('titles.feedback_overview')}
                                    </Menu.Item>
                                    :
                                    null
                                }

                                {userStore.isAdmin ?
                                    <Menu.Item
                                        active={this.activeElement === "admin"}
                                        onClick={() => {
                                            history.push("/" + this.props.i18n.language + "/admin");
                                            this.showMenu = false;
                                            this.setState({});
                                        }}
                                        key="admin">
                                        {i18n.t('titles.admin')}
                                    </Menu.Item>
                                    :
                                    null
                                }
                                <Menu.Item
                                    style={{marginRight: "0", paddingBottom: "0", paddingTop: "0"}}>
                                    <Dropdown item icon='world' simple
                                              direction='left'
                                              className={'notranslate'}
                                              text={this.getCurrentLanguage()}
                                              style={{marginRight: "0", paddingTop: "8px", paddingBottom: "0"}}>
                                        <Dropdown.Menu>
                                            {this.getDropdownItems()}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Menu.Item>

                                {userStore.userFromServer !== null ?
                                    <Menu.Item
                                        active={this.activeElement === "user-profile"}
                                        onClick={() => {
                                            history.push("/" + this.props.i18n.language + "/user-profile");
                                            this.showMenu = false;
                                            this.setState({});
                                        }}
                                        key="user-profile">
                                        {i18n.t('titles.user_profile')}
                                    </Menu.Item>
                                    :
                                    null}
                                {
                                    userStore.userFromServer !== null ? <Menu.Item
                                            position='right'
                                            onClick={() => {
                                                removeTokenFromStorage();
                                                history.push("/" + this.props.i18n.language + "/login");
                                                userStore.user = null;
                                                this.showMenu = false;
                                                this.setState({})
                                            }}
                                            active={true}
                                            key="logout">
                                            <Button basic
                                                    className={"nav-button"}
                                                    style={{fontSize: "18px"}}>
                                                <Icon
                                                    name={"arrow left"}/>
                                                {i18n.t('titles.logout')}
                                            </Button>
                                        </Menu.Item>
                                        :
                                        null
                                }
                            </Menu>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column comuter={1} only={'computer'}/>
                </Responsive>
                <Responsive as={Grid.Row} minWidth={801} className={"nav-row"} centered>
                    <Grid.Column computer={4} tablet={5}>
                        <Segment className={"navigation-menu-segment"}>
                            <Menu key="menu" pointing secondary stackable className={"navigation-menu"}>
                                {/*<Menu.Item
                                    active={this.activeElement === ""}
                                    onClick={() => {
                                        history.push("/"+this.props.i18n.language+"/");
                                        this.showMenu = false;
                                        this.setState({});
                                    }}
                                    key="landing">
                                    {i18n.t("titles.landing").toUpperCase()}
                                </Menu.Item>

                                {userStore.userFromServer === null &&
                                <Menu.Item
                                    active={this.activeElement === "register"}
                                    onClick={() => {
                                        history.push("/"+this.props.i18n.language+"/register");
                                        this.showMenu = false;
                                        this.setState({});
                                    }}
                                    key="register">
                                    {i18n.t("login_page.register").toUpperCase()}
                                </Menu.Item>}*/}

                                <Menu.Item
                                    active={this.activeElement === "/overview"}
                                    onClick={() => {
                                        history.push("/" + this.props.i18n.language + "/overview");
                                        this.showMenu = false;
                                        this.setState({});
                                    }}
                                    key="home">
                                    {i18n.t('titles.own_overview').toUpperCase()}
                                </Menu.Item>

                                {userStore.userFromServer !== null ?
                                    <Menu.Item
                                        active={this.activeElement === "user-profile"}
                                        onClick={() => {
                                            history.push("/" + this.props.i18n.language + "/user-profile");
                                            this.showMenu = false;
                                            this.setState({});
                                        }}
                                        key="user-profile">
                                        {i18n.t('titles.user_profile').toUpperCase()}
                                    </Menu.Item>
                                    :
                                    null}

                                <Dropdown
                                    item icon='globe' simple
                                    direction='left'
                                    text={this.getCurrentLanguage()}
                                    style={{marginRight: "0", paddingTop: "8px"}}>
                                    <Dropdown.Menu>
                                        {this.getDropdownItems()}
                                    </Dropdown.Menu>
                                </Dropdown>

                            </Menu>
                        </Segment>
                    </Grid.Column>
                    {!userStore.isAdmin &&
                    <Grid.Column width={3}>
                        <Segment className={"navigation-menu-segment"}>
                            <Menu key="menu" pointing secondary stackable className={"navigation-menu"}>
                                <Menu.Item
                                    active={this.activeElement === "admin"}
                                    style={{margin: "auto"}}
                                    key="logo">
                                    <Image
                                        alt={"Pekana Logo"}
                                        style={{height: "40px", paddingTop: "10px", paddingBottom: "10px"}}
                                        className={"login-logo"}
                                        src={config.PUBLIC_URL + (i18n.language.includes("en") ? "images/Logo_en.jpg" : "images/Logo.png")}/>
                                </Menu.Item>
                            </Menu>
                        </Segment>
                    </Grid.Column>}
                    <Grid.Column computer={userStore.isAdmin ? 7 : 4} tablet={5}>
                        <Segment className={"navigation-menu-segment"}>
                            <Menu key="menu" pointing secondary stackable className={"navigation-menu"}>
                                {userStore.isAdmin ?
                                    <Menu.Item
                                        active={this.activeElement === "questionnaires-overview"}
                                        onClick={() => {
                                            history.push("/" + this.props.i18n.language + "/questionnaires-overview");
                                            this.showMenu = false;
                                            this.setState({});
                                        }}
                                        key="quest_over_2">
                                        {i18n.t('titles.questionnaires_overview')}
                                    </Menu.Item>
                                    :
                                    null
                                }
                                {userStore.isAdmin ?
                                    <Menu.Item
                                        active={this.activeElement === "products"}
                                        onClick={() => {
                                            history.push("/" + this.props.i18n.language + "/products");
                                            this.showMenu = false;
                                            this.setState({});
                                        }}
                                        key="products">
                                        {i18n.t('titles.products')}
                                    </Menu.Item>
                                    :
                                    null
                                }
                                {userStore.isReviewer || userStore.isAdmin ?
                                    <Menu.Item
                                        active={this.activeElement === "review-overview"}
                                        onClick={() => {
                                            history.push("/" + this.props.i18n.language + "/review-overview");
                                            this.showMenu = false;
                                            this.setState({});
                                        }}
                                        key="rev_over">
                                        {i18n.t('titles.review_overview')}
                                    </Menu.Item>
                                    :
                                    null
                                }
                                {userStore.isAdmin ?
                                    <Menu.Item
                                        active={this.activeElement === "feedback-overview"}
                                        onClick={() => {
                                            history.push("/" + this.props.i18n.language + "/feedback-overview");
                                            this.showMenu = false;
                                            this.setState({});
                                        }}
                                        key="feedback_overview">
                                        {i18n.t('titles.feedback_overview')}
                                    </Menu.Item> :
                                    null}
                                {userStore.isAdmin ?
                                    <Menu.Item
                                        active={this.activeElement === "admin"}
                                        onClick={() => {
                                            history.push("/" + this.props.i18n.language + "/admin");
                                            this.showMenu = false;
                                            this.setState({});
                                        }}
                                        key="admin">
                                        {i18n.t('titles.admin')}
                                    </Menu.Item>
                                    :
                                    null
                                }

                                {userStore.userFromServer !== null ?
                                    <Menu.Item
                                        position={"right"}
                                        onClick={() => {
                                            removeTokenFromStorage();
                                            userStore.user = null;
                                            history.push("/" + this.props.i18n.language + "/");
                                            this.setState({})
                                        }}
                                        active={true}
                                        key="logout">
                                        <Button
                                            style={{marginTop: "5px"}}
                                            className={"logout-button"}>
                                            {i18n.t('titles.logout').toUpperCase()}
                                        </Button>
                                    </Menu.Item>
                                    : null
                                }
                                {/*
                                  <Menu.Item
                                        active={this.activeElement === "login"}
                                        position={"right"}
                                        onClick={() => {
                                            history.push("/"+this.props.i18n.language+"/login");
                                            this.showMenu = false;
                                            this.setState({});
                                        }}
                                        key="login">
                                        <Button
                                            style={{marginTop: "5px"}}
                                            className={"login-button"}>
                                            {i18n.t("login_page.log_in").toUpperCase()}
                                        </Button>
                                    </Menu.Item>*/}
                            </Menu>
                        </Segment>
                    </Grid.Column>
                </Responsive>
                <Grid.Row
                    centered
                    className={"page-title-image landing-blue"}>
                    <Image
                        className={"image-overlay-top"}
                        alt={""}
                        src={config.PUBLIC_URL + "images/overlay-top.png"}/>
                    <Image
                        className={"image-overlay-bottom"}
                        alt={""}
                        src={config.PUBLIC_URL + "images/overlay-bottom.png"}/>
                    <div>
                        <Image
                            className={"title-logo"}
                            alt={"PEKANA logo"}
                            src={config.PUBLIC_URL + "images/pekana_dot.png"}/>
                    </div>
                </Grid.Row>
            </Grid>);
    }
}

function removeLanguageFromString(string) {

    let filteredString = string;
    for (let i = 0; i < sharedConfig.LANGUAGES.length; i++) {
        let selectedLanguage = sharedConfig.LANGUAGES[i];
        if (selectedLanguage.language) {
            filteredString = filteredString.replace(selectedLanguage.language + "/", "")
        }
    }

    return filteredString;
}