import React from "react";
import {withTranslation} from "react-i18next";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import config from "../../config/main.config";
import {observer} from "mobx-react";
import {observable} from "mobx";
import EditSimpleValueComponent from "../components/EditSimpleValueComponent";
import EditHTMLComponent from "../components/EditHTMLComponent";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import history from "../helper/browserHistory";
import SeparatorRow from "../components/SeparatorRow";

@withTranslation()
@observer
export default class LandingPage extends React.Component {

    @observable
    height = 0;

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.updateDimensions();
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        this.height = window.innerHeight;
    };

    render() {
        const {i18n, t} = this.props;
        let language = "de-DE";

        if (this.props.i18n) {
            language = this.props.i18n.language;
        }
        let languageForURL = "/de-DE";

        if (language !== "de-DE") {
            languageForURL = "/en-EN";
        }


        return (
            <Grid
                style={{backgroundImage: "url(" + config.PUBLIC_URL + "images/Pflanze1.png), url(" + config.PUBLIC_URL + "images/Pflanze2.png)",}}
                className={"main-background-images"}>
                <SeparatorRow/>
                <Grid.Row centered>
                    <Grid.Column computer={9} tablet={14} mobile={14} textAlign={"center"}>
                        <h1 className={"landing-title-header"}>
                            <EditSimpleValueComponent id={"landing-title-1"}/>
                        </h1>
                        <p className={"landing-subheader"}>
                            <EditSimpleValueComponent id={"landing-subtitle-1"}/>
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column computer={3} tablet={7} mobile={14} className={"landing-card-container"}>
                        <div
                            className={"landing-image-container"}>
                            <Image
                                fluid
                                className={"landing-image"}
                                src={config.PUBLIC_URL + "images/landing_card_1.jpeg"}/>
                        </div>
                        <h2 className={"landing-subtitle"}>
                            <EditSimpleValueComponent id={"landing-card-1-title"}/>
                        </h2>
                        <p>
                            <EditSimpleValueComponent id={"landing-card-1-text"}/>
                        </p>
                    </Grid.Column>
                    <Grid.Column computer={3} tablet={7} mobile={14} className={"landing-card-container"}>
                        <div
                            className={"landing-image-container"}>
                            <Image
                                fluid
                                className={"landing-image"}
                                src={config.PUBLIC_URL + "images/landing_card_2.jpeg"}/>
                        </div>
                        <h2 className={"landing-subtitle"}>
                            <EditSimpleValueComponent id={"landing-card-2-title"}/>
                        </h2>
                        <p>
                            <EditSimpleValueComponent id={"landing-card-2-text"}/>
                        </p>
                    </Grid.Column>
                    <Grid.Column computer={3} tablet={7} mobile={14} className={"landing-card-container"}>
                        <div
                            className={"landing-image-container"}>
                            <Image
                                fluid
                                className={"landing-image"}
                                src={config.PUBLIC_URL + "images/landing_card_3.jpeg"}/>
                        </div>
                        <h2 className={"landing-subtitle"}>
                            <EditSimpleValueComponent id={"landing-card-3-title"}/>
                        </h2>
                        <p>
                            <EditSimpleValueComponent id={"landing-card-3-text"}/>
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row
                    centered
                    style={{background: "#47878a", marginBottom: "30px"}}>
                    <Image
                        className={"image-overlay-top"}
                        alt={""}
                        src={config.PUBLIC_URL + "images/overlay-top.png"}/>
                    <Image
                        className={"image-overlay-bottom"}
                        alt={""}
                        src={config.PUBLIC_URL + "images/overlay-bottom.png"}/>
                    <Grid.Column computer={9} tablet={14} mobile={14}
                                 textAlign="center"
                                 style={{paddingTop: "50px", paddingBottom: "50px"}}>
                        <h2 className={"landing-subtitle-white"}>
                            <EditSimpleValueComponent id={"landing-title-2"}/>
                        </h2>
                        <div style={{width: "100%", textAlign: "left"}}>
                            <div style={{margin: "auto", width: "fit-content"}}>
                                <p className={"landing-editor"}>
                                    <EditHTMLComponent id={"landing-text-2"}/>
                                </p>
                            </div>
                        </div>

                        <Button
                            className={"landing-button"}
                            style={{
                                backgroundImage: " url('" + config.PUBLIC_URL + "images/buttonwhite.png" + "')"
                            }}
                            onClick={() => history.push("/"+this.props.i18n.language+"/register")}>
                            {t("titles.take_part").toUpperCase()}
                        </Button>

                    </Grid.Column>
                </Grid.Row>
                <SeparatorRow/>
                <Grid.Row centered>
                    <Grid.Column computer={9} tablet={14} mobile={14}>
                        <h2 className={"landing-subtitle-bottom"} style={{textAlign: "center"}}>
                            <EditSimpleValueComponent id={"landing-title-3"}/>
                        </h2>
                        <p className={"landing-editor-dark"}>
                            <EditHTMLComponent id={"landing-text-3"}/>
                        </p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}