import React from "react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import userStore from "../stores/userStore";
import history from "../helper/browserHistory";
import {observable} from "mobx";
import {withTranslation} from "react-i18next";
import {observer} from "mobx-react";
import {getTokenFromLocalStorage} from "../helper/util";
import SeparatorRow from "../components/SeparatorRow";
import config from "../../config/main.config";

@withTranslation()
@observer
export default class UserOverviewPage extends React.Component {

    @observable
    user = null;

    @observable
    answeredQuestionnaires = -1;

    componentDidMount() {
        this.user = userStore.userFromServer;
        if (this.user == null) {
            history.push("/"+this.props.i18n.language+"/login");
        }

        window.scrollTo(0, 0);
        this.fetchAmountAnswered();
    }

    fetchAmountAnswered() {
        let self = this;
        //Get all products
        fetch(config.BASE_URL + "feedbacks/answered-mount", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    if (json.hasOwnProperty("amount")) {
                        self.answeredQuestionnaires = json.amount;
                    } else {
                        self.answeredQuestionnaires = 0;
                    }
                });

            } else {
                //console.log(response.status)
            }
        }).catch(
            error => {
                //console.log(error);
                if (error.status === 401) {
                    history.replace("/"+this.props.i18n.language+"/login");
                }
            }
        );
    }


    render() {
        let {i18n} = this.props;

        return (
            <Grid>
                <SeparatorRow/>
                <Grid.Row centered>
                    <Grid.Column computer={10} tablet={12} mobile={15} textAlign={"center"}>
                        <h2 className={"landing-title-header"}>
                           {i18n.t('titles.user_profile')}
                        </h2>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column computer={5} tablet={8} mobile={15}>
                        {this.user != null ?
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={8} textAlign={"right"}>
                                        <b>
                                            {i18n.t('user_profile.first_name')}
                                        </b>
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        {this.user.firstName}
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={8} textAlign={"right"}>
                                        <b>
                                            {i18n.t('user_profile.last_name')}
                                        </b>
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        {this.user.lastName}
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={8} textAlign={"right"}>
                                        <b>
                                            {i18n.t('user_profile.email')}
                                        </b>
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        {this.user.email}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            :
                            null
                        }
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    {this.answeredQuestionnaires !== -1 ?
                        <Grid.Column textAlign={"center"}>
                                <span>
                                <b>
                                    {i18n.t('user_profile.feedbacks') + ": "}
                                </b>
                                    {this.answeredQuestionnaires}
                                </span>
                        </Grid.Column>
                        : null
                    }
                </Grid.Row>
            </Grid>

        );
    }
}