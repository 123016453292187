import React from "react";
import {observable} from "mobx";
import {observer} from "mobx-react";
import history from "../helper/browserHistory"
import userStore from "../stores/userStore";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import SeparatorRow from "../components/SeparatorRow";
import TransitionablePortal from "semantic-ui-react/dist/commonjs/addons/TransitionablePortal";
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";

@withTranslation()
@observer
export default class LoginPage extends React.Component {

    @observable user = {
        username: '',
        password: ''
    };

    @observable loginError = {
        username: false,
        password: false,
        logIn: false
    };

    @observable openRegisterModal = false;

    @observable loading = false;

    @observable showLoginSuccess = false;

    constructor() {
        super();
        this.user = {
            username: '',
            password: ''
        };

        this.login = this.login.bind(this);
    }

    register() {
        history.push("/"+this.props.i18n.language+'/register')
    }


    login() {
        this.loading = true;
        this.loginError.logIn = false;

        if (this.user.username === "") {
            this.loading = false;
            this.loginError.username = true;
        } else if (this.user.password === "") {
            this.loading = false;
            this.loginError.password = true;
        } else {
            userStore.authenticateUser(this.user).then((success) => {
                if (success) {
                    this.showLoginSuccess = true;
                    setTimeout(() => {
                        history.push("/"+this.props.i18n.language+'/overview');
                    }, 3000);
                } else {
                    this.loginError.logIn = true;
                }
            });
            this.loading = false;
        }

    }

    handleChange(prop, e) {
        this.loginError.username = false;
        this.loginError.password = false;
        this.user[prop] = e.target.value;
    }


    render() {
        let {i18n} = this.props;

        return (
            <Grid>
                <SeparatorRow/>
                <Grid.Row centered>
                    <Grid.Column computer={5} tablet={8} mobile={16} textAlign={"center"}>
                        <h2 className={"landing-title-header"} style={{hyphens: "auto"}}>
                            {i18n.t("login_page.title")}
                        </h2>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column computer={4} tablet={6} mobile={14} textAlign={"center"}>
                        <p>
                            {i18n.t("login_page.login_text")}
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className={"no-top-padding"}>
                    <Grid.Column computer={4} tablet={6} mobile={14}>
                        <Form size="large" className={"contact-form"} error={this.loginError.logIn}>
                            <Form.Field>
                                <label>{i18n.t("login_page.user").toUpperCase()}</label>
                                <Form.Input value={this.user.username}
                                            error={this.loginError.username}
                                            onChange={this.handleChange.bind(this, "username")}
                                            type='text'/>
                            </Form.Field>
                            <Form.Field>
                                <label>{i18n.t("login_page.password").toUpperCase()}</label>
                                <Form.Input value={this.user.password}
                                            error={this.loginError.password}
                                            onChange={this.handleChange.bind(this, "password")}
                                            type='Password'/>
                            </Form.Field>

                            <Message
                                error
                                header='Login fehlgeschlagen'
                                content='Eingabe überprüfen und erneut versuchen.'
                            />
                            <Button onClick={this.login} type='submit'
                                    className={"questionnaire-button"}
                                    inverted>
                                {i18n.t("login_page.log_in")}
                            </Button>
                            <Button
                                onClick={() => history.push("/"+this.props.i18n.language+"/register")}
                                className={"questionnaire-button"}
                                inverted>
                                {i18n.t("login_page.register")}
                            </Button>
                            <br/>
                            <br/>
                            <Link to={"/password-forgot"}
                                  style={{color: "#F39A38"}}>  {i18n.t("login_page.password_forgot")}
                            </Link>
                        </Form>
                    </Grid.Column>
                </Grid.Row>
                <TransitionablePortal
                    closeOnTriggerClick
                    transition={{animation: 'fly up', duration: 700}}
                    open={this.showLoginSuccess}>
                    <div className={"login-fly-up"}>
                        <Grid style={{paddingTop: "10em"}}>
                            <SeparatorRow/>
                            <Grid.Row centered>
                                <Grid.Column textAlign={"center"} computer={5} tablet={8} mobile={15}>
                                    <h2 className={"landing-title-header"}>
                                        {i18n.t("login_page.title")}
                                    </h2>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row centered>
                                <Grid.Column textAlign={"center"} computer={5} tablet={8} mobile={15}>
                                    <p>
                                        {i18n.t("login_page.subtitle")}
                                    </p>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row centered>
                                <Grid.Column textAlign={"center"} computer={5} tablet={8} mobile={15}>
                                    <Loader active inline='centered'/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                    </div>

                </TransitionablePortal>
            </Grid>
        );
    }
}