import React from "react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import {withTranslation} from "react-i18next";
import userStore from "../stores/userStore";
import history from "../helper/browserHistory";
import {observer} from "mobx-react";
import {observable, toJS} from "mobx";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import Checkbox from "semantic-ui-react/dist/commonjs/modules/Checkbox";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

import config from "../../config/main.config";
import {getTokenFromLocalStorage} from "../helper/util";
import {stateFromHTML} from "draft-js-import-html";
import draftToHtml from 'draftjs-to-html';
import Radio from "semantic-ui-react/dist/commonjs/addons/Radio";
import sharedConfig from "../../../shared/sharedConfig";
import {EditorState, convertToRaw} from "draft-js";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";


let Editor = null;
if (typeof window !== "undefined") {
    Editor = require("react-draft-wysiwyg").Editor;
}


const ANSWER_TYPES = [1, 2, 7, 8]

/**
 * Page to create Questionnaires
 */
@withTranslation()
@observer
export default class CreateQuestionnairePage extends React.Component {

    @observable
    pages = [];

    @observable
    title = {};

    @observable
    active = 1;

    @observable
    global = false;

    @observable
    main = true;

    @observable
    password = "";

    @observable
    questionTypes = [];

    @observable
    questionnaireId = "";

    @observable
    editorState = null;

    @observable
    editorState2 = null;

    @observable
    language = 'de-DE';

    @observable
    standardFill = {};

    @observable
    loading = false;

    @observable
    admissionCountries = "";

    @observable
    pageIndex = null

    constructor(props) {
        super(props);
        const editorFill = {};
        const standardFill = {};
        for (let i = 0; i < sharedConfig.LANGUAGES.length; i++) {
            editorFill[sharedConfig.LANGUAGES[i].language] = EditorState.createEmpty();
            standardFill[sharedConfig.LANGUAGES[i].language] = "";
        }
        this.editorState = editorFill;
        this.editorState2 = editorFill;
        this.standardFill = standardFill;
    }

    componentDidMount() {
        if (userStore.userFromServer == null || !userStore.isAdmin) {
            history.push("/" + this.props.i18n.language + "/login");
        }

        window.scrollTo(0, 0);
        this.setQuestionTypes();
        this.questionnaireId = this.props.match.params.id === "0" ? "" : this.props.match.params.id;

        if (this.questionnaireId !== "") {
            this.getCurrentQuestionnaire();
        } else {
            this.title = toJS(this.standardFill)
        }
    }

    onEditorStateChange(editorState) {
        this.editorState[this.language] = editorState;
        this.setState({...this.state});
    };

    onEditorState2Change(editorState) {
        this.editorState2[this.language] = editorState;
        this.setState({...this.state});
    };

    getCurrentQuestionnaire() {
        fetch(config.BASE_URL + "questionnaires/newest/" + this.questionnaireId, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    if (json.hasOwnProperty("pages")) {
                        //create editor state in titles
                        for (let i = 0; i < json.pages.length; i++) {
                            for (let j = 0; j < json.pages[i].questions.length; j++) {
                                let localeEditorTitle = {};
                                for (let k = 0; k < sharedConfig.LANGUAGES.length; k++) {
                                    let language = sharedConfig.LANGUAGES[k].language;
                                    if (json.info.hasOwnProperty(language)) {
                                        let contentState = stateFromHTML(json.pages[i].questions[j].title[language]);
                                        localeEditorTitle[language] = EditorState.createWithContent(contentState);
                                    } else {
                                        localeEditorTitle[language] = EditorState.createEmpty();
                                    }
                                }

                                json.pages[i].questions[j].title = localeEditorTitle
                            }
                        }

                        this.pages = json.pages;
                        this.title = json.title;
                        this.main = json.main;
                        this.global = json.global;
                        this.password = json.password;
                        this.admissionCountries = json.admissionCountries || "";

                        let productDetail = {};
                        let productDetail2 = {};
                        for (let i = 0; i < sharedConfig.LANGUAGES.length; i++) {
                            let language = sharedConfig.LANGUAGES[i].language;
                            if (json.info.hasOwnProperty(language)) {
                                let contentState = stateFromHTML(json.info[language]);
                                productDetail[language] = EditorState.createWithContent(contentState);
                            } else {
                                productDetail[language] = EditorState.createEmpty();
                            }
                            if (json.firstPage.hasOwnProperty(language)) {
                                let contentState = stateFromHTML(json.firstPage[language]);
                                productDetail2[language] = EditorState.createWithContent(contentState);
                            } else {
                                productDetail2[language] = EditorState.createEmpty();
                            }
                        }
                        this.editorState = productDetail
                        this.editorState2 = productDetail2
                    }
                });
            }
        }).catch(
        );
    }

    setQuestionTypes() {
        let {i18n} = this.props;
        /*
        Ja/Nein.
         Optionen aus mehreren Optionen (Radio Button).
         Mehrfachanwahl (Checkbox).
         Skalierfragen (z.B. 1,2,3,4,5).
         */
        this.questionTypes = [
            {
                key: 0,
                value: 0,
                text: i18n.t('questionnaires_create.yes_no')
            },
            {
                key: 1,
                value: 1,
                text: i18n.t('questionnaires_create.single_choice')
            },
            {
                key: 2,
                value: 2,
                text: i18n.t('questionnaires_create.multiple_choice')
            },
            {
                key: 3,
                value: 3,
                text: i18n.t('questionnaires_create.scale')
            },
            {
                key: 4,
                value: 4,
                text: i18n.t('questionnaires_create.indication')
            },
            {
                key: 5,
                value: 5,
                text: i18n.t('questionnaires_create.text')
            }, {
                key: 6,
                value: 6,
                text: i18n.t('questionnaires_create.product')
            },
            {
                key: 7,
                value: 7,
                text: i18n.t('questionnaires_create.single_choice_drop')
            },
            {
                key: 8,
                value: 8,
                text: i18n.t('questionnaires_create.multiple_choice_drop')
            },
        ];
    }

    handleQuestionChange(index, pageIndex, type, event) {
        this.pages[pageIndex].questions[index][type] = event.target.value;
    }

    toggleAdmissionLanguage(language) {
        if (this.admissionCountries.includes(language)) {
            this.admissionCountries = this.admissionCountries.replace(language, "");
        } else {

            this.admissionCountries += " " + language;
        }
    }

    handleQuestionMandatoryChange(index, pageIndex) {
        this.pages[pageIndex].questions[index].mandatory = this.pages[pageIndex].questions[index].mandatory === 1 ? 0 : 1;
        this.setState({...this.state});
    }

    handleQuestionTitleChange(index, pageIndex, editorState) {
        this.pages[pageIndex].questions[index].title[this.language] = editorState;
    }

    handleAnswerOptionChange(questionIndex, pageIndex, index, type, event) {
        this.pages[pageIndex].questions[questionIndex].answerOptions[index][type] = event.target.value;
    }

    handleAnswerOptionTitleChange(questionIndex, pageIndex, index, type, event) {
        this.pages[pageIndex].questions[questionIndex].answerOptions[index][type][this.language] = event.target.value;
    }

    changeQuestionType(index, pageIndex, event, data) {
        this.pages[pageIndex].questions[index].type = data.value;
        if (data.value === 3) {
            this.pages[pageIndex].questions[index].answerOptions = [
                {
                    title: toJS(this.standardFill),
                    order: 0
                }, {
                    title: toJS(this.standardFill),
                    order: 1
                }
            ]
        } else if (data.value === 0) {
            this.pages[pageIndex].questions[index].answerOptions = [];
        }
    }

    showAllQuestions(questions, pageIndex) {
        let {i18n} = this.props;
        return questions.map((question, index) =>
            <Grid.Row key={"quest" + index}>
                <Grid.Column width={16}>
                    <Segment>
                        <Form>
                            <Form.Group widths='equal'>
                                <Form.Field>
                                    <label>{i18n.t('questionnaires_create.question')}</label>
                                    <Segment>
                                        {Editor && <Editor
                                            editorState={question.title[this.language]}
                                            toolbarClassName="default-editor"
                                            onEditorStateChange={this.handleQuestionTitleChange.bind(this, index, pageIndex)}
                                            toolbar={{
                                                options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign',
                                                    'colorPicker', 'link', 'embedded', 'emoji', 'remove', 'history'],
                                            }}
                                            localization={{
                                                locale: "de"
                                            }}
                                        />}
                                    </Segment>
                                </Form.Field>
                                <Form.Field width={3}>
                                    <label>{i18n.t('questionnaires_create.type')}</label>
                                    <Dropdown
                                        fluid
                                        selection
                                        options={toJS(this.questionTypes)}
                                        onChange={this.changeQuestionType.bind(this, index, pageIndex)}
                                        value={question.type}
                                    />
                                </Form.Field>
                                <Form.Input
                                    label={i18n.t('questionnaires_create.order')}
                                    width={1}
                                    value={question.order}
                                    onChange={this.handleQuestionChange.bind(this, index, pageIndex, 'order')}/>
                                <Form.Field width={2}>
                                    <label>
                                        Pflichtfeld
                                    </label>
                                    <Checkbox
                                        checked={question.mandatory === 1}
                                        onChange={this.handleQuestionMandatoryChange.bind(this, index, pageIndex)}
                                    />
                                </Form.Field>
                            </Form.Group>
                            {ANSWER_TYPES.includes(question.type) ?
                                <Form.Field>
                                    <label>{i18n.t('questionnaires_create.answers')}</label>
                                    <Segment>
                                        {this.getAnswerOptions(question.answerOptions, index, pageIndex)}
                                        <Button
                                            positive
                                            onClick={() => this.pages[pageIndex].questions[index].answerOptions.push(
                                                {title: toJS(this.standardFill), order: 0}
                                            )}>
                                            {i18n.t('add')}
                                        </Button>
                                    </Segment>
                                </Form.Field>
                                :
                                null
                            }
                            {question.type === 3 ?
                                <Form.Field>
                                    <label>{i18n.t('questionnaires_create.answers')}</label>
                                    <Segment>
                                        {this.getScaleOptions(question.answerOptions, index, pageIndex)}
                                    </Segment>
                                </Form.Field>
                                :
                                null
                            }
                            <Button
                                negative
                                onClick={() => this.pages[pageIndex].questions.splice(index, 1)}>
                                {i18n.t('delete')}
                            </Button>
                        </Form>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        );
    }

    getScaleOptions(answerOptions, questionIndex, pageIndex) {
        let {i18n} = this.props;
        return <Form>
            <Form.Group widths='equal'>
                <Form.Input
                    label={i18n.t('questionnaires_create.answer')}
                    placeholder={i18n.t('questionnaires_create.start')}
                    value={answerOptions[0].title[this.language]}
                    onChange={this.handleAnswerOptionTitleChange.bind(this, questionIndex, pageIndex, 0, "title")}/>
                <Form.Input
                    label={i18n.t('questionnaires_create.end')}
                    value={answerOptions[1].title[this.language]}
                    onChange={this.handleAnswerOptionTitleChange.bind(this, questionIndex, pageIndex, 1, 'title')}/>
            </Form.Group>
        </Form>;
    }

    getAnswerOptions(answerOptions, questionIndex, pageIndex) {
        let {i18n} = this.props;
        return answerOptions.map((option, index) =>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Input
                        label={i18n.t('questionnaires_create.answer')}
                        placeholder={i18n.t('questionnaires_create.answer')}
                        value={option.title[this.language]}
                        onChange={this.handleAnswerOptionTitleChange.bind(this, questionIndex, pageIndex, index, "title")}/>
                    <Form.Input
                        label={i18n.t('questionnaires_create.order')}
                        width={1}
                        value={option.order}
                        onChange={this.handleAnswerOptionChange.bind(this, questionIndex, pageIndex, index, 'order')}/>
                    <Form.Field
                        width={1}>
                        <label>
                            {i18n.t('delete')}
                        </label>
                        <Button
                            basic
                            negative
                            onClick={() => this.pages[pageIndex].questions[questionIndex].answerOptions.splice(index, 1)}>
                            <Icon name={"delete"}/>
                        </Button>
                    </Form.Field>
                </Form.Group>
            </Form>);
    }

    addQuestion(pageIndex) {
        const editorFill = {};
        for (let i = 0; i < sharedConfig.LANGUAGES.length; i++) {
            editorFill[sharedConfig.LANGUAGES[i].language] = EditorState.createEmpty();
        }
        this.pages[pageIndex].questions.push({
            title: editorFill,
            type: 0,
            answerOptions: [],
            order: this.pages[pageIndex].questions.length
        })
    }

    addQuestionnaire() {
        //questionnaire info
        this.loading = true;
        let questionnaireInfo = {};
        for (let i = 0; i < sharedConfig.LANGUAGES.length; i++) {
            let language = sharedConfig.LANGUAGES[i].language;
            questionnaireInfo[language] = draftToHtml(convertToRaw(this.editorState[language].getCurrentContent()))
        }

        let questionnaireFirstPage = {};
        for (let i = 0; i < sharedConfig.LANGUAGES.length; i++) {
            let language = sharedConfig.LANGUAGES[i].language;
            questionnaireFirstPage[language] = draftToHtml(convertToRaw(this.editorState2[language].getCurrentContent()))
        }

        //create
        let createItem = {
            active: this.active,
            main: this.main,
            global: this.global,
            password: this.password,
            title: this.title,
            pages: this.checkQuestions(toJS(this.pages)),
            questionnaireId: this.questionnaireId,
            admissionCountries: this.admissionCountries,
            info: questionnaireInfo,
            firstPage: questionnaireFirstPage
        };

        fetch(config.BASE_URL + 'questionnaires', {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(createItem)
        }).then((resp) => resp.json())
            .then(article => {
                this.loading = false;
                history.push("/" + this.props.i18n.language + "/questionnaires-overview");

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    addPage() {
        this.pages.push({
            questions: [],
            order: this.pages.length
        })
    }

    removePage(pageIndex) {
        if (this.pages[pageIndex].questions.length > 0 && this.pageIndex === null) {
            this.pageIndex = pageIndex
        } else {
            this.pages.splice(pageIndex, 1);
            this.pageIndex = null;
        }
    }

    checkQuestions(pages) {
        for (let k = 0; k < pages.length; k++) {
            for (let i = 0; i < pages[k].questions.length; i++) {

                let questionnaireInfo = {};
                for (let j = 0; j < sharedConfig.LANGUAGES.length; j++) {
                    let language = sharedConfig.LANGUAGES[j].language;
                    questionnaireInfo[language] = draftToHtml(convertToRaw(pages[k].questions[i].title[language].getCurrentContent()));
                }

                pages[k].questions[i].title = questionnaireInfo
                //Yes/No
                if (pages[k].questions[i].type === 0) {
                    pages[k].questions[i].answerOptions = [
                        {title: "yes", order: 0},
                        {title: "no", order: 1}];
                }
                if (pages[k].questions[i].type === 3 && pages[k].questions[i].answerOptions.length !== 2) {
                    if (pages[k].questions[i].answerOptions.length === 0) {
                        pages[k].questions[i].answerOptions = [
                            {title: "0", order: 0},
                            {title: "1", order: 1}];
                    }
                    if (pages[k].questions[i].answerOptions.length > 2) {
                        pages[k].questions[i].answerOptions = pages[k].questions[i].answerOptions.subarray(0, 2)
                    }
                }
            }
        }

        return pages
    }

    showAllPages(pages, i18n) {
        let showPages = [];

        for (let i = 0; i < pages.length; i++) {
            showPages.push(<Grid.Row centered>
                <Grid.Column width={14}>
                    <Segment>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <h2>{"Seite: " + (i + 1)}</h2>
                                </Grid.Column>
                            </Grid.Row>
                            {this.showAllQuestions(pages[i].questions, i)}
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Button color='green'
                                            onClick={this.addQuestion.bind(this, i)}>
                                        <strong>  {i18n.t('questionnaires_create.add_question')}</strong>
                                    </Button>
                                    <Button color='red'
                                            floated='right'
                                            onClick={this.removePage.bind(this, i)}>
                                        <strong>  {i18n.t('questionnaires_create.remove_page')}</strong>
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Grid.Column>
            </Grid.Row>);
        }

        return showPages;
    }

    askDeletePageModal(i18n) {
        return (
            <Modal open={this.pageIndex !== null} style={{margin: "auto"}}>
                <Modal.Header>{i18n.t('questionnaires_create.remove_page')}?</Modal.Header>
                <Modal.Content>{i18n.t('questionnaires_create.remove_page_with_question')}</Modal.Content>
                <Modal.Actions>
                    <Button color='green'
                            onClick={this.removePage.bind(this, this.pageIndex)}>
                        <strong>  {i18n.t('questionnaire.option_yes')}</strong>
                    </Button>
                    <Button color='red'
                            onClick={() => this.pageIndex = null}>
                        <strong>  {i18n.t('questionnaire.option_no')}</strong>
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }

    handleLanguageChange(language) {
        this.language = language;
        this.setState({});
    }

    render() {
        let {i18n} = this.props;

        let showPages = this.showAllPages(this.pages, i18n);

        const askDeletePageModal = this.askDeletePageModal(i18n);

        const admissionLanguageButtons = sharedConfig.LANGUAGES.map(language => (
            <Form.Field key={"b" + language.language}
                        control='input' type='checkbox'
                        label={language.label}
                        value={language.language}
                        checked={this.admissionCountries.includes(language.language)}
                        onChange={() => this.toggleAdmissionLanguage(language.language)}/>
        ));

        const languageRadioButtons = sharedConfig.LANGUAGES.map(language => (
            <Form.Field key={language.language}>
                <Radio
                    label={language.label}
                    name='radioGroup'
                    value={language.language}
                    checked={this.language === language.language}
                    onChange={() => this.handleLanguageChange(language.language)}
                />
            </Form.Field>));

        return (
            <Grid>
                <Grid.Row centered>
                    <Grid.Column width={14}>
                        <Form>
                            <Form.Group>
                                <label>Sprache</label><br/>
                                {languageRadioButtons}
                            </Form.Group>
                            <Form.Field>
                                <label>Info Text (Angezeigt auf der Kachel)</label>
                                <Segment>
                                    {Editor && <Editor
                                        editorState={this.editorState[this.language]}
                                        toolbarClassName="default-editor"
                                        onEditorStateChange={this.onEditorStateChange.bind(this)}
                                        toolbar={{
                                            options: ['inline', 'blockType', 'fontSize', 'list',
                                                'textAlign', 'colorPicker', 'link', 'embedded',
                                                'emoji', 'remove', 'history'],
                                        }}
                                        localization={{
                                            locale: "de"
                                        }}
                                    />}
                                </Segment>
                            </Form.Field>
                            <Form.Field>
                                <label>Info Text 2 (Angezeigt auf der 1. Seite)</label>
                                <Segment>
                                    {Editor && <Editor editorState={this.editorState2[this.language]}
                                                       toolbarClassName="default-editor"
                                                       onEditorStateChange={this.onEditorState2Change.bind(this)}
                                                       toolbar={{
                                                           options: ['inline', 'blockType', 'fontSize', 'list',
                                                               'textAlign', 'colorPicker', 'link', 'embedded',
                                                               'emoji', 'remove', 'history'],
                                                       }}
                                                       localization={{
                                                           locale: "de"
                                                       }}
                                    />}
                                </Segment>
                            </Form.Field>
                            <Form.Input
                                label={i18n.t('questionnaires_create.title')}
                                placeholder={i18n.t('questionnaires_create.title')}
                                value={this.title[this.language]}
                                onChange={e => this.title[this.language] = e.target.value}/>
                            <Form.Field>
                                <Form.Field>
                                    <label>Veröffentlichen in</label>
                                    <Form.Group>
                                        {admissionLanguageButtons}
                                    </Form.Group>
                                </Form.Field>
                                <Checkbox
                                    label={i18n.t('questionnaires_overview.active')}
                                    checked={this.active}
                                    onChange={() => this.active = this.active === 1 ? 0 : 1}/>
                            </Form.Field>
                            <Form.Field>
                                <Checkbox
                                    label={i18n.t('questionnaires_overview.main')}
                                    checked={this.main}
                                    onChange={() => this.main = !this.main}/>
                            </Form.Field>
                            <Form.Field>
                                <Checkbox
                                    label={i18n.t('questionnaires_overview.global')}
                                    checked={this.global}
                                    onChange={() => this.global = !this.global}/>
                            </Form.Field>
                            <Form.Input
                                label={i18n.t('questionnaires_overview.password')}
                                placeholder={i18n.t('questionnaires_overview.password')}
                                value={this.password}
                                onChange={e => this.password = e.target.value}/>
                        </Form>
                    </Grid.Column>
                </Grid.Row>
                {showPages}
                <Grid.Row centered>
                    <Grid.Column width={14}>
                        <Button
                            color='green'
                            onClick={this.addPage.bind(this)}>
                            <strong>  {i18n.t('questionnaires_create.add_page')}</strong>
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column width={14}>
                        <Button
                            color='green'
                            loading={this.loading}
                            disabled={this.loading}
                            onClick={this.addQuestionnaire.bind(this)}>
                            <strong>  {i18n.t('save')}</strong>
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                {askDeletePageModal}
            </Grid>

        );
    }
}