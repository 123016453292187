import React from "react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import {withTranslation} from "react-i18next";
import userStore from "../stores/userStore";
import history from "../helper/browserHistory";
import {observer} from "mobx-react";
import {observable, toJS} from "mobx";
import {getTokenFromLocalStorage} from "../helper/util";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import {Checkbox} from "semantic-ui-react";

import config from "../../config/main.config";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Accordion from "semantic-ui-react/dist/commonjs/modules/Accordion";

/**
 * Overview Questionnaires
 * Delete & Set Active
 * Links to Edit questionnaires
 */
@withTranslation()
@observer
export default class QuestionnaireOverviewPage extends React.Component {

    @observable
    questionnaires = [];

    /*
     * [{title: "xxxx", questionnaireId: "xxxxx", versions: []}]
     */
    @observable
    sortedQuestionnaires = [];

    @observable
    activeIndex = -1;


    componentDidMount() {
        if (userStore.userFromServer == null || !userStore.isAdmin) {
            history.push("/"+this.props.i18n.language+"/login");
        }

        window.scrollTo(0, 0);
        this.fetchQuestionnaires();
    }

    fetchQuestionnaires() {
        let self = this;
        //Get all products
        fetch(config.BASE_URL + "questionnaires/all", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    self.questionnaires = json;
                    //sort
                    let questionnaireIds = [];
                    let sortedQuestionnaires = [];
                    for (let i = 0; i < json.length; i++) {
                        if (!questionnaireIds.includes(json[i].questionnaireId)) {
                            questionnaireIds.push(json[i].questionnaireId);
                            let sortQuestionnaire = {
                                title: json[i].title,
                                questionnaireId: json[i].questionnaireId,
                                versions: [json[i]]
                            };
                            sortedQuestionnaires.push(sortQuestionnaire)
                        } else {
                            let index = questionnaireIds.indexOf(json[i].questionnaireId);
                            sortedQuestionnaires[index].versions.push(json[i]);
                        }
                    }
                    self.sortedQuestionnaires = sortedQuestionnaires;
                    self.setState({});
                });

            } else {
                //console.log(response.status)
            }
        }).catch(
            error => {
                //console.log(error);
                if (error.status === 401) {
                    history.replace("/"+this.props.i18n.language+"/login");
                }
            }
        );
    }


    setQuestionnaireActive(id, questionnaireId) {
        let self = this;
        fetch(config.BASE_URL + "questionnaires/active/" + id + "/" + questionnaireId, {
            method: "PUT",
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    self.fetchQuestionnaires();
                });

            } else {
                //console.log(response.status)
            }
        }).catch(
            error => {
                //console.log(error);
                if (error.status === 401) {
                    history.replace("/"+this.props.i18n.language+"/login");
                }
            }
        );
    }

    deleteQuestionnaire(id,) {
        let self = this;
        fetch(config.BASE_URL + "questionnaires/" + id, {
            method: "DELETE",
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    self.fetchQuestionnaires();
                });

            } else {
                //console.log(response.status)
            }
        }).catch(
            error => {
                //console.log(error);
                if (error.status === 401) {
                    history.replace("/"+this.props.i18n.language+"/login");
                }
            }
        );
    }

    showAllSortedQuestionnaires(sortedQuestionnaires) {
        let {i18n} = this.props;
        let showQuestionnaires = [];

        for (let i = 0; i < sortedQuestionnaires.length; i++) {
            showQuestionnaires.push(<Accordion.Title
                    key={"t" + i}
                    onClick={() => this.activeIndex = this.activeIndex === i ? -1 : i}>
                    <h2>
                        <Icon
                            name={this.activeIndex === i ? 'angle up' : 'angle down'}/>
                        {sortedQuestionnaires[i].title[i18n.language]}
                        <Button
                            floated='right'
                            icon labelPosition='right'
                            onClick={() =>
                                history.push("/"+this.props.i18n.language+'/create/' + sortedQuestionnaires[i].questionnaireId)
                            }
                            color='green'>
                            <strong>{i18n.t('questionnaires_overview.add_version')}</strong>
                            <Icon name='plus'/>
                        </Button>
                    </h2>
                </Accordion.Title>
            );

            let versions = sortedQuestionnaires[i].versions.map((questionnaire, index) =>
                <Table.Row key={"sq" + index}>
                    <Table.Cell widht={10} textAlign={"center"}>
                        {questionnaire.version}
                    </Table.Cell>
                    <Table.Cell widht={2}>
                        <Checkbox
                            toggle
                            checked={questionnaire.active === 1}
                            onClick={() => this.setQuestionnaireActive(questionnaire.id, sortedQuestionnaires[i].questionnaireId)}/>
                    </Table.Cell>
                    <Table.Cell widht={2}>
                        <Button color={"teal"}
                                floated={"right"}
                                basic
                                onClick={() =>
                                    history.push("/"+this.props.i18n.language+"/questionnaire/" + questionnaire.id)}>
                            <Icon name='arrow right'/>
                        </Button>
                    </Table.Cell>
                    <Table.Cell widht={2}>
                        <Button color={"red"}
                                floated={"right"}
                                basic
                                onClick={() => this.deleteQuestionnaire(questionnaire.id)}>
                            <Icon name='delete'/>
                        </Button>
                    </Table.Cell>
                </Table.Row>);

            showQuestionnaires.push(<Accordion.Content key={"c" + i} active={this.activeIndex === i}>
                <Table celled basic='very'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={8}>Version</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Aktiv</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Betrachten</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Löschen</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {versions}
                    </Table.Body>
                </Table>
            </Accordion.Content>);
        }


        return <Accordion
            defaultActiveIndex={toJS(this.activeIndex)}
            fluid
            styled
            exclusive={true}>
            {showQuestionnaires}
        </Accordion>

    }

    getProductName(products, productId) {
        for (let i = 0; i < products.length; i++) {
            if (products[i].productId === productId) {
                return products[i].title
            }
        }

        return "";
    }

    render() {
        let {i18n} = this.props;

        let sortedQuestionnaires = this.showAllSortedQuestionnaires(this.sortedQuestionnaires);
        return (
            <Grid>
                <Grid.Row centered>
                    <Grid.Column computer={10} tablet={12} mobile={15}
                                 style={{"marginBottom": "10px"}}>
                        <Button
                            floated='right'
                            icon labelPosition='right'
                            onClick={() =>
                                history.push("/"+this.props.i18n.language+'/create/0')
                            }
                            color='green'>
                            <strong>{i18n.t('questionnaires_overview.add')}</strong>
                            <Icon name='plus'/>
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column computer={10} tablet={12} mobile={15}>
                        {sortedQuestionnaires}
                    </Grid.Column>
                </Grid.Row>
            </Grid>

        );
    }
}