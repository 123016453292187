import config from "../../config/main.config"
import userStore from "../stores/userStore"

export function isDevelop() {
    return process.env.NODE_ENV === 'development' || typeof process.env.NODE_ENV === 'undefined';
}

export function isAdmin() {
    if (getTokenFromLocalStorage() !== null && typeof getTokenFromLocalStorage() !== "undefined") {
        return true;
    } else if (userStore.user !== null) {
        return true;
    }

    return false;
}

export function getTokenFromLocalStorage() {
    if (typeof window !== "undefined") {
        let object = JSON.parse(localStorage.getItem(config.STORAGE_KEY));

        if (object === null) {
            return null;
        }

        let oneDayAfterTokenDate = new Date(object.timestamp);
        oneDayAfterTokenDate.setDate(oneDayAfterTokenDate.getDate() + 1);

        if (oneDayAfterTokenDate.getTime() > new Date().getTime()) {
            return object.token;
        } else {
            removeTokenFromStorage();
            return null;
        }
    }

    return null;
}

export function removeTokenFromStorage() {
    if (typeof window !== "undefined") {
        localStorage.removeItem(config.STORAGE_KEY);
        localStorage.removeItem(config.USER_STORAGE_KEY);
    }
}

export function setTokenLocalStorage(token) {
    if (typeof window !== "undefined") {
        let object = {
            "token": token,
            timestamp: new Date().getTime()
        };

        localStorage.setItem(config.STORAGE_KEY, JSON.stringify(object));
    }
}


export function setUserToLocalStorage(user) {
    if (typeof window !== "undefined") {
        let object = {
            "user": user,
            timestamp: new Date().getTime()
        };

        localStorage.setItem(config.USER_STORAGE_KEY, JSON.stringify(object));
    }

}


export function getUserFromLocalStorage() {
    if (typeof window !== "undefined") {
        let object = JSON.parse(localStorage.getItem(config.USER_STORAGE_KEY));

        if (object === null) {
            return null;
        }

        let oneDayAfterTokenDate = new Date(object.timestamp);
        oneDayAfterTokenDate.setDate(oneDayAfterTokenDate.getDate() + 1);

        if (oneDayAfterTokenDate.getTime() > new Date().getTime()) {
            return object.user;
        } else {
            removeTokenFromStorage();
            return null;
        }
    }

    return null;

}

export function showBlobFile(blob, filename) {

    if (typeof window !== "undefined") {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        let newBlob = new Blob([blob], {type: "text/csv"});

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        let link = document.createElement('a');
        link.href = data;

        link.download = filename + ".csv";

        link.click();
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
        }, 100);
    }
}

export function toReadableDate(dateString) {
    let d = new Date(dateString);
    const year = d.getFullYear();
    const day = d.getDate();
    const month = d.getMonth() + 1;

    return (day <= 9 ? "0" + day : day) + "." + (month <= 9 ? "0" + month : month) + "." + year;
}