import React from "react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import userStore from "../stores/userStore";
import history from "../helper/browserHistory";
import {observable} from "mobx";
import {withTranslation} from "react-i18next";
import {observer} from "mobx-react";
import {getTokenFromLocalStorage, toReadableDate} from "../helper/util";
import config from "../../config/main.config";
import Accordion from "semantic-ui-react/dist/commonjs/modules/Accordion";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

@withTranslation()
@observer
export default class ReviewOverviewPage extends React.Component {

    @observable
    feedbacks = [];

    @observable
    activeIndex = 0;

    @observable
    editFeedback = {
        createdAt: "",
        questions: [],
        answerQuestions: []
    };

    @observable
    showEditModal = false;

    @observable
    loading = true;

    componentDidMount() {
        if (userStore.userFromServer == null || !userStore.isAdmin) {
            history.push("/" + this.props.i18n.language + "/login");

        }

        window.scrollTo(0, 0);
        this.getFeedbacks()
    }

    getFeedbacks() {
        let self = this;
        //Get all products
        fetch(config.BASE_URL + "feedbacks/no_review", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    self.feedbacks = json;
                    self.loading = false;
                    self.setState({});
                });

            } else {
                //console.log(response.status)
            }
        }).catch(
            error => {
                //console.log(error);
                if (error.status === 401) {
                    history.replace("/" + this.props.i18n.language + "/login");
                }
            }
        );
    }

    setFeedbackType(id, type) {
        let self = this;
        //Get all products
        fetch(config.BASE_URL + "feedbacks", {
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({id: id, type: type})
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                self.getFeedbacks();
                self.showEditModal = false;
            } else {
                //console.log(response.status)
            }
        }).catch(
            error => {
                //console.log(error);
                if (error.status === 401) {
                    history.replace("/" + this.props.i18n.language + "/login");
                }
            }
        );
    }

    getShowFeedbacks(feedbacks) {
        let showFeedbacks = [];
        let {i18n} = this.props;

        for (let i = 0; i < feedbacks.length; i++) {
            showFeedbacks.push(
                <Accordion.Title
                    active={this.activeIndex === i}
                    index={i}
                    onClick={() => this.activeIndex = i}>
                    {feedbacks[i].title[i18n.language]}<br/>
                    {"Version: " + feedbacks[i].version}<br/>
                    {" Zu bearbeiten: " + feedbacks[i].feedbacks.length}
                </Accordion.Title>
            );

            let answeredFeedbacks = [];

            for (let j = 0; j < feedbacks[i].feedbacks.length; j++) {
                answeredFeedbacks.push
                (<Grid.Row>
                    <Grid.Column width={10}>{toReadableDate(feedbacks[i].feedbacks[j].createdAt)}</Grid.Column>
                    <Grid.Column width={6}>
                        <Button positive floated={"right"}
                                onClick={() => {
                                    this.editFeedback = feedbacks[i].feedbacks[j];
                                    this.showEditModal = true;
                                }}>
                            Bearbeiten
                        </Button>
                    </Grid.Column>
                </Grid.Row>)
            }


            showFeedbacks.push(
                <Accordion.Content active={this.activeIndex === i}>
                    <Grid divided='vertically'>
                        {answeredFeedbacks}
                    </Grid>
                </Accordion.Content>
            )
        }


        return showFeedbacks;
    }

    getAnsweredQuestions(questions) {
        let {i18n} = this.props;
        let showAnsweredQuestions = [];
        for (let j = 0; j < questions.length; j++) {
            let answerText = "nicht beantwortet";
            if (questions[j].hasOwnProperty("answerText")) {
                answerText = questions[j].answerText;
                if (answerText.constructor === Object) {
                    answerText = JSON.parse(answerText)[i18n.language];
                }
            }
            showAnsweredQuestions.push(
                <Grid.Row>
                    <b dangerouslySetInnerHTML={{__html: questions[j].title[i18n.language]}}/>
                </Grid.Row>
            );
            showAnsweredQuestions.push(
                <Grid.Row>
                    {answerText}
                </Grid.Row>
            )
        }
        return showAnsweredQuestions;
    }


    render() {

        let showFeedbacks = this.getShowFeedbacks(this.feedbacks);
        return (
            <Grid>
                <Grid.Row centered>
                    {!this.loading && this.feedbacks.length > 0 ?
                        <Grid.Column computer={8} tablet={10} mobile={15}>
                            <Accordion styled>
                                {showFeedbacks}
                            </Accordion>
                        </Grid.Column>
                        : null}
                    {!this.loading && this.feedbacks.length === 0 ?
                        <Grid.Column computer={8} tablet={10} mobile={15} textAlign={"center"}>
                            <p>Alles bearbeitet <Icon name={"check"}/></p>
                        </Grid.Column>
                        :
                        null
                    }
                    {this.loading ?
                        <Grid.Column computer={8} tablet={10} mobile={15} textAlign={"center"}>
                            <Loader active/>
                        </Grid.Column>
                        : null
                    }
                </Grid.Row>
                <Modal open={this.showEditModal} onClose={() => this.showEditModal = false}
                       className={"margin-auto"}>
                    <Modal.Header>{toReadableDate(this.editFeedback.createdAt)}</Modal.Header>
                    <Modal.Content scrolling>
                        <Grid>
                            {this.getAnsweredQuestions(this.editFeedback.questions)}
                        </Grid>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button.Group>
                            <Button
                                positive
                                onClick={() => this.setFeedbackType(this.editFeedback.id, 1)}>
                                Systematik
                            </Button>
                            <Button
                                color={"orange"}
                                onClick={() => this.setFeedbackType(this.editFeedback.id, 2)}>
                                Information
                            </Button>
                        </Button.Group>
                    </Modal.Actions>
                </Modal>
            </Grid>

        );
    }
}