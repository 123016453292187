import LoginPage from "../react/js/pages/LoginPage";
import QuestionnairePage from "../react/js/pages/QuestionnairePage";
import UserProfilePage from "../react/js/pages/UserProfilePage";

import UserOverviewPage from "../react/js/pages/UserOverviewPage";
import AdministrationPage from "../react/js/pages/AdministrationPage";
import ProductPage from "../react/js/pages/ProductPage";
import CreateQuestionnairePage from "../react/js/pages/CreateQuestionnairePage";
import QuestionnaireOverviewPage from "../react/js/pages/QuestionnaireOverviewPage";
import ReviewOverviewPage from "../react/js/pages/ReviewOverviewPage";
import FeedbackOverviewPage from "../react/js/pages/FeedbackOverviewPage";
import LoaderComponent from "../react/js/components/LoaderComponent";
import Loadable from "react-loadable";
import LandingPage from "../react/js/pages/LandingPage";
import KasuistikPage from "../react/js/pages/KasuistikPage";
import languages from "./sharedConfig";
import {Redirect} from "react-router";
import React from "react";

//import RegisterPage from "../react/js/pages/RegisterPage";
const RegisterPage = Loadable({
    loader: () => import(/* webpackChunkName: 'RegisterPage' */ "../react/js/pages/RegisterPage"),
    loading: () => LoaderComponent
});

//import PasswordForgotPage from "../react/js/pages/PasswordForgotPage";
const PasswordForgotPage = Loadable({
    loader: () => import(/* webpackChunkName: 'PasswordForgotPage' */ "../react/js/pages/PasswordForgotPage"),
    loading: () => LoaderComponent
});


let sharedRoutes = [];
for (let i = 0; i < languages.LANGUAGES.length; i++) {
    sharedRoutes = sharedRoutes.concat([
        {
            path: "/" + languages.LANGUAGES[i].language + "/",
            exact: true,
            component: LandingPage
        },
        {
            path: "/" + languages.LANGUAGES[i].language + "/overview",
            exact: true,
            component: UserOverviewPage
        },
        {
            path: "/" + languages.LANGUAGES[i].language + "/login",
            exact: true,
            component: LoginPage
        },
        {
            path: "/" + languages.LANGUAGES[i].language + "/admin",
            exact: true,
            component: AdministrationPage
        },
        {
            path: "/" + languages.LANGUAGES[i].language + "/products",
            exact: true,
            component: ProductPage
        },
        {
            path: "/" + languages.LANGUAGES[i].language + "/create/:id",
            exact: true,
            component: CreateQuestionnairePage
        },
        {
            path: "/" + languages.LANGUAGES[i].language + "/questionnaire/:id",
            exact: true,
            component: QuestionnairePage
        },
        {
            path: "/" + languages.LANGUAGES[i].language + "/questionnaires-overview",
            exact: true,
            component: QuestionnaireOverviewPage
        },
        {
            path: "/" + languages.LANGUAGES[i].language + "/feedback-overview",
            exact: true,
            component: FeedbackOverviewPage
        },
        {
            path: "/" + languages.LANGUAGES[i].language + "/user-profile",
            exact: true,
            component: UserProfilePage
        }, {
            path: "/" + languages.LANGUAGES[i].language + "/review-overview",
            exact: true,
            component: ReviewOverviewPage
        }, {
            path: "/" + languages.LANGUAGES[i].language + "/register",
            exact: true,
            component: RegisterPage
        }, {
            path: "/" + languages.LANGUAGES[i].language + "/password-forgot",
            exact: true,
            component: PasswordForgotPage
        }, {
            path: "/" + languages.LANGUAGES[i].language + "/kasuistik",
            exact: true,
            component: KasuistikPage
        }
    ]);
}
export default sharedRoutes;