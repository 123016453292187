export default {
    LANGUAGES: [
        {
            language: 'en-CA',
            label: 'CA',
            regexToSearch: /^\/en-CA*/
        },
        {
            language: 'de-DE',
            label: 'DE',
            regexToSearch: '/'
        },
        {
            language: 'en-US',
            label: 'US',
            regexToSearch: /^\/en-US*/
        },
        {
            language: 'en-IN',
            label: 'IN',
            regexToSearch: /^\/en-IN*/
        },
        {
            language: 'en-PK',
            label: 'PK',
            regexToSearch: /^\/en-PK*/
        },

        /*,
        {
            language:'en-EN',
            label:'EN',
            regexToSearch: /^\/en-EN/
        }
        */
    ]
}