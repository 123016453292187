import React from "react";
import {observer} from "mobx-react";
import {withTranslation} from "react-i18next";
import EditSimpleValueComponent from "./EditSimpleValueComponent";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import config from "../../config/main.config";


@withTranslation()
@observer
export default class FooterComponent extends React.Component {


    render() {
        const {i18n} = this.props;
        let language = "de-DE";
        if (this.props.i18n) {
            language = this.props.i18n.language;
        }

        let languageForURL = "/de-DE";

        if (language !== "de-DE") {
            languageForURL = "/" + language;
        }

        return (
            [<Grid.Row
                centered
                style={{background: "#DDDBD8", paddingTop: "70px", marginBottom: "0 !imoprtant"}}>
                <Image
                    className={"image-overlay-top"}
                    alt={""}
                    src={config.PUBLIC_URL + "images/overlay-top.png"}/>
                <Grid.Column computer={9} tablet={14} mobile={14}
                             textAlign={"center"}
                             style={{paddingTop: "20px"}}>
                    <h2 className={"footer-title"}>
                        <EditSimpleValueComponent id={"landing-title-4"}/>
                    </h2>
                </Grid.Column>
            </Grid.Row>,
                <Grid.Row>
                    <Grid.Column computer={9} tablet={14} mobile={14}
                                 textAlign={"center"}>
                        <div style={{display: "inline-flex", textAlign: "center"}}>
                            <p onClick={() => {
                               if(languageForURL.endsWith("CA") || languageForURL.endsWith("US")){
                                   window.open("https://www.pekana.com/en-EN/impressum")
                               }else{
                                window.open("https://www.pekana.com" + languageForURL + "/impressum")
                               }
                            }}
                               className={"footer-link"}>
                                {i18n.t('titles.imprint').toUpperCase()}
                            </p>
                            {/*   <div style={{paddingLeft: "25px"}}/>
                            <p onClick={() => window.open("https://www.pekana.com" + languageForURL)}
                               className={"footer-link"}>
                                {i18n.t('titles.to_website').toUpperCase()}
                            </p>*/}

                        </div>
                    </Grid.Column>
                </Grid.Row>

            ]
        );
    }
}