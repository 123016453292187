import React from "react"
import {inject, observer} from "mobx-react";

import NavigationBar from "../components/NavigationBar"
import {renderRoutes} from "react-router-config";
import allRoutes from "../../../shared/routes";
import {withTranslation} from "react-i18next";

import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import {Helmet} from "react-helmet";
import FooterComponent from "../components/FooterComponent";


if (typeof document !== "undefined") {
    // Require scss files
    require('../../stylesheets/_all.scss');
}

@withTranslation()
@inject("stores")
@observer
export default class Layout extends React.Component {

    render() {
        const {i18n} = this.props;
        let language = "de-DE";

        if (this.props.i18n) {
            language = this.props.i18n.language;
        }

        return (
            <Grid centered columns={2}>
                <Helmet
                    htmlAttributes={{"lang": language}}
                />
                <Grid.Row style={{position: "fixed", "background": "white", "zIndex": "3"}}>
                    <Grid.Column width={16}>
                        <NavigationBar/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className={"page-content"}>
                    <Grid.Column style={{ "minHeight": "70vh"}} width={16}>
                        {renderRoutes(allRoutes)}
                    </Grid.Column>
                </Grid.Row>
                <FooterComponent/>
            </Grid>
        );
    }
}