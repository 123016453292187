import React from "react";
import {inject, observer} from "mobx-react";
import {observable, toJS} from "mobx";
import {Button, Form, Grid, Modal, Table} from "semantic-ui-react";
import config from "../../config/main.config";
import {getTokenFromLocalStorage, isAdmin} from "../helper/util";
import userStore from "../stores/userStore";
import history from "../helper/browserHistory";
import Radio from "semantic-ui-react/dist/commonjs/addons/Radio";

const DEFAULT_USER = {
    id: "",
    firstName: "",
    nameError: false,
    lastName: "",
    lastNameError: false,
    username: "",
    usernameError: false,
    email: "",
    password: "",
    passwordError: false,
    admin: false,
    roles: []
};

@inject("stores")
@observer
export default class UserManagementPage extends React.Component {

    @observable users = [];
    @observable roles = [];
    @observable loading = true;

    @observable addModalOpen = false;

    //User data used for add and edit
    //ALWAYS RESET AFTER USING
    @observable
    addEditUser = DEFAULT_USER;

    @observable deleteModalOpen = false;
    @observable deleteUserId = "";

    @observable errorMessage = "";
    @observable errorModalOpen = false;


    /**
     * Scrolls page up when loaded
     */
    componentDidMount() {
        if (userStore.userFromServer == null || !isAdmin()) {
            history.push("/"+this.props.i18n.language+"/overview");
        }

        window.scrollTo(0, 0);

        this.getUsers();
        this.getRoles();
    }

    getUsers() {
        fetch(config.BASE_URL + "users/all", {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((resp) => resp.json())
            .then(data => {
                let feedbackUsers = [];
                for (let i = 0; i < data.length; i++) {
                    if (data[i].hasOwnProperty("roles")) {
                        for (let j = 0; j < data[i].roles.length; j++) {
                            let role = data[i].roles[j];
                            if (role.startsWith("feedback_") || role === "Admin") {
                                feedbackUsers.push(data[i]);
                                break;
                            }
                        }
                    }
                }
                this.users = feedbackUsers;
                this.loading = false;
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    getRoles() {
        fetch(config.BASE_URL + "users/roles/all", {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((resp) => resp.json())
            .then(data => {
                this.roles = data;
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    showDeleteModal(id) {
        if (this.users.length > 1) {
            this.deleteUserId = id;
            this.deleteModalOpen = true;
        }
    }

    deleteUser() {
        fetch(config.BASE_URL + 'users/' + this.deleteUserId, {
            method: 'DELETE',
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then(response => {
            if (response.status >= 200 && response.status < 300) {
                //Load Users
                this.getUsers();
                this.deleteUserId = "";
                this.deleteModalOpen = false;
            } else {
                this.errorMessage = "Network error";
                this.errorModalOpen = true;
            }
        }).catch(function (error) {
            console.log("bad", error);
        });
    }

    usernameExists(username, edit = false) {
        for (let i = 0; i < this.users.length; i++) {
            if (this.users[i].username === username) {
                if (edit) {
                    if (i !== this.editUserIndex) {
                        return true;
                    }
                } else {
                    return true;
                }
            }
        }
        return false;
    }

    /*
     * Returns true if a needed parameter is empty
     */
    isEmptyForm(edit = false) {
        let isEmpty = false;
        if (this.addEditUser.username === "") {
            this.addEditUser.usernameError = true;
            isEmpty = true;
        } else {
            this.addEditUser.usernameError = false;
        }
        if (this.addEditUser.name === "") {
            this.addEditUser.nameError = true;
            isEmpty = true;
        } else {
            this.addEditUser.nameError = false;
        }
        if (this.addEditUser.lastName === "") {
            this.addEditUser.lastNameError = true;
            isEmpty = true;
        } else {
            this.addEditUser.lastNameError = false;
        }

        return isEmpty;
    }

    addUser() {
        if (this.isEmptyForm()) {
            return;
        }
        let method = "POST";
        if (this.addEditUser.id !== "") {
            method = "PUT";
        }

        if (this.usernameExists(this.username)) {
            this.errorMessage = "User already exists";
            this.errorModalOpen = true;
            return;
        }

        return fetch(config.BASE_URL + "users/register", {
            method: method,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify(toJS(this.addEditUser))
        })
            .then(response => {
                this.loading = false;
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        if (json.hasOwnProperty("id")) {
                            //Load Users
                            this.getUsers();
                            this.addModalOpen = false;
                            this.addEditUser = DEFAULT_USER
                        } else {
                            this.errorMessage = "Network error";
                            this.errorModalOpen = true;
                        }
                    });

                } else {
                    this.errorMessage = "Network error";
                    this.errorModalOpen = true;
                }
            })
            .catch(
                error => {
                    this.errorMessage = error;
                    this.errorModalOpen = true;
                }
            );

    }

    handleInputChange(type, event) {
        this.addEditUser[type] = event.target.value;
    }

    openModal(open) {
        this.addModalOpen = open;
        this.addEditUser = DEFAULT_USER;
    }

    showEditModal(index, id) {
        this.addEditUser = {
            id: this.users[index].id,
            firstName: this.users[index].firstName,
            nameError: false,
            lastName: this.users[index].lastName,
            lastNameError: false,
            username: this.users[index].username,
            usernameError: false,
            email: this.users[index].email,
            roles: []
        };
        this.editUserIndex = index;
        this.userId = id;
        for (let i = 0; i < this.users[index].roles.length; i++) {
            for (let j = 0; j < this.roles.length; j++) {
                if (this.roles[j].title === this.users[index].roles[i]) {
                    this.addEditUser.roles.push(this.roles[j].id);
                    break;
                }
            }
        }

        this.addModalOpen = true;
    }

    handleRoleChange(id) {
        this.addEditUser.roles = [id];
    }

    getUserAccordion() {
        let tableRoles = [];
        for (let i = 0; i < this.roles.length; i++) {
            if (this.roles[i].title.startsWith("feedback_")) {

                tableRoles.push(<Form.Field key={"role" + i}>
                    <Radio
                        label={this.roles[i].title.replace("feedback_", "")}
                        value={this.roles[i].id}
                        checked={this.addEditUser.roles.includes(this.roles[i].id)}
                        onChange={() => this.handleRoleChange(this.roles[i].id)}
                    />
                </Form.Field>);
            }
        }

        return (
            <Form>
                {tableRoles}
            </Form>);
    }

    /**
     * Returns Blog page
     * @return {XML} html of this component
     */
    render() {
        let tableAuthors = this.users.map((author, index) =>
            <Table.Row key={index}>
                <Table.Cell>{author.id}</Table.Cell>
                <Table.Cell>{author.firstName + " " + author.lastName}</Table.Cell>
                <Table.Cell>{author.username}</Table.Cell>
                <Table.Cell>{author.email}</Table.Cell>
                <Table.Cell>
                    {author.roles.join(", ")}
                </Table.Cell>
                <Table.Cell>
                    <button className="ui basic icon negative button" role="button"
                            onClick={this.showDeleteModal.bind(this, author.id)}>
                        <i aria-hidden="true"
                           className="remove icon"/>
                    </button>
                </Table.Cell>
                <Table.Cell>
                    <button className="ui basic icon positive button" role="button"
                            onClick={this.showEditModal.bind(this, index, author.id)}>
                        <i aria-hidden="true"
                           className="edit icon"/>
                    </button>
                </Table.Cell>
            </Table.Row>
        );

        return (
            <Grid>
                <Modal open={this.addModalOpen} onClose={this.openModal.bind(this, false)}
                       className={"margin-auto"}>
                    <Modal.Header>{this.addEditUser.id === "" ? "User hinzufügen" : "User bearbeiten"}</Modal.Header>
                    <Modal.Content scrolling>
                        <Form>
                            <Form.Input fluid label='Vorname' placeholder='Vorname' type="text"
                                        value={this.addEditUser.firstName}
                                        onChange={this.handleInputChange.bind(this, 'firstName')}
                                        error={this.addEditUser.nameError}/>
                            <Form.Input fluid label='Nachname' placeholder='Nachname' type="text"
                                        value={this.addEditUser.lastName}
                                        onChange={this.handleInputChange.bind(this, 'lastName')}
                                        error={this.addEditUser.lastNameError}/>
                            <Form.Input fluid label='Username' placeholder='Username' type="text"
                                        value={this.addEditUser.username}
                                        onChange={this.handleInputChange.bind(this, 'username')}
                                        error={this.addEditUser.usernameError}/>
                            <Form.Input fluid label='Email' placeholder='email' type="text"
                                        value={this.addEditUser.email}
                                        onChange={this.handleInputChange.bind(this, 'email')}/>
                            <Form.Input fluid label='Passwort' placeholder='Passwort' type="text"
                                        value={this.addEditUser.password}
                                        onChange={this.handleInputChange.bind(this, 'password')}/>
                            <Form.Field>
                                <label>Rollen</label>
                                {this.getUserAccordion()}
                            </Form.Field>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button positive icon='checkmark' labelPosition='right'
                                content={this.addEditUser.id === "" ? "hinzufügen" : "bearbeiten"}
                                onClick={this.addUser.bind(this)}/>
                    </Modal.Actions>
                </Modal>

                <Modal open={this.deleteModalOpen} onClose={() => this.deleteModalOpen = false}
                       className={"margin-auto"}>
                    <Modal.Header>User entfernen</Modal.Header>
                    <Modal.Content>
                        <p>Sind Sie sich sicher diesen User zu löschen?</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button positive icon='checkmark' labelPosition='right' content='Löschen'
                                onClick={this.deleteUser.bind(this)}/>
                    </Modal.Actions>
                </Modal>

                <Modal open={this.errorModalOpen} onClose={() => this.errorModalOpen = false}
                       className={"margin-auto"}>
                    <Modal.Header>Error</Modal.Header>
                    <Modal.Content>
                        <p>{this.errorMessage}</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button positive icon='checkmark' labelPosition='right' content='OK'
                                onClick={() => {
                                    this.errorModalOpen = false;
                                    this.errorMessage = ""
                                }}/>
                    </Modal.Actions>
                </Modal>
                <Grid.Row>
                    <Grid.Column computer={5} tablet={10} mobile={14} className={"underline-column"}>
                        <h1 className={"page-header"}>
                            Nutzer
                        </h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>ID</Table.HeaderCell>
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    <Table.HeaderCell>Username</Table.HeaderCell>
                                    <Table.HeaderCell>Email</Table.HeaderCell>
                                    <Table.HeaderCell>Rolle</Table.HeaderCell>
                                    <Table.HeaderCell>Entfernen</Table.HeaderCell>
                                    <Table.HeaderCell>Bearbeiten</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {tableAuthors}
                            </Table.Body>
                        </Table>
                        <Button positive onClick={this.openModal.bind(this, true)}>Hinzufügen</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}