import React from "react";
import PropTypes from "prop-types";
import {observer} from "mobx-react";
import {observable} from "mobx";
import EditSimpleValueComponent from "./EditSimpleValueComponent";
import EditTextButtonComponent from "./EditTextButtonComponent";
import config from "../../config/main.config";
import userStore from "../stores/userStore";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";

@observer
export default class EditCardComponent extends React.Component {

    @observable
    infoModalOpen = false;


    @observable
    width = 0;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.updateDimensions();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        this.width = window.innerWidth;
    };


    render() {
        const {
            headerId, subheaderId, color, buttonId, buttonClass, wideWidth, computerWidth, computerStyle, mobileStyle
        } = this.props;
        if (!headerId || !subheaderId || !buttonId) {
            console.log('Src is not acceptable');
            return null;
        }

        let imageSrc = config.PUBLIC_URL + "images/red.png";
        if (color) {
            if (color === "orange") {
                imageSrc = config.PUBLIC_URL + "images/orange.png";
            } else if (color === "blue") {
                imageSrc = config.PUBLIC_URL + "images/blue.png";
            } else if (color === "red") {
                imageSrc = config.PUBLIC_URL + "images/red.png";
            }
        }

        let displayType = userStore.isAdmin ? "admin" : "user";
        let admin = displayType === "admin";


        return (
            <Card fluid className={"card-shadow"}>
                <Card.Content>
                    <Card.Header>
                        <div className={admin ? "" : "card-title-container"}>
                            <Image
                                alt={"Bild zur Überschrift"}
                                src={imageSrc}
                                className={admin ? "hide-div" : "card-bullet-point"}
                            />
                            <h3 className={admin ? "" : "card-header"}><EditSimpleValueComponent id={headerId}/>
                            </h3>
                        </div>

                    </Card.Header>
                    <Card.Description>
                        <p className={"card-text"}><EditSimpleValueComponent id={subheaderId}/></p>
                        <div className={admin ? "" : "card-button-bottom"}>
                            <EditTextButtonComponent id={buttonId} cssClass={buttonClass} arrow={true}
                                                     color={color}/>
                        </div>
                    </Card.Description>
                </Card.Content>
            </Card>
        )
    }
}

/* <EditIconButtonComponent cssClass={"card-button card-button-position"} textId={buttonTextId}
                          linkTextId={buttonLinkId}/>*/
EditCardComponent.propTypes = {
    headerId: PropTypes.string,
    subheaderId: PropTypes.string,
    color: PropTypes.string,
    buttonId: PropTypes.string,
    buttonClass: PropTypes.string,
    wideWidth: PropTypes.number,
    computerWidth: PropTypes.number,
    computerStyle: PropTypes.object,
    mobileStyle: PropTypes.object,
};

EditCardComponent.defaultProps = {
    headerId: null,
    subheaderId: null,
    color: null,
    buttonId: null,
    buttonClass: null,
    wideWidth: 3,
    computerWidth: 4,
    computerStyle: {},
    mobileStyle: {}
};