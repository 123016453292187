/**
 * Created by Matijas on 11.04.2017.
 */

import { isDevelop } from "../js/helper/util";

export default {
    BASE_URL: isDevelop() ? "http://localhost:3003/api/" : "https://ask.pekana.com/api/",
    PUBLIC_URL: isDevelop() ? "http://localhost:3003/" : "https://ask.pekana.com/",
    STORAGE_KEY: "*b&8WFpxu9~]]j_",
    USER_STORAGE_KEY: "TDd8<WrfB<2Fbe^="
}