import React from "react";
import PropTypes from "prop-types";
import {inject, observer} from "mobx-react";
import {withTranslation} from "react-i18next";
import userStore from "../stores/userStore"
import Loadable from 'react-loadable';
import LoaderComponent from "../components/LoaderComponent";

const TextStoreHTMLEditor = Loadable({
    loader: () => import(/* webpackChunkName: 'TextStoreHTMLEditor' */ "./TextStoreHTMLEditor"),
    loading: () => LoaderComponent
});


@inject("stores")
@withTranslation()
@observer
class EditHTMLComponent extends React.Component {

    render() {
        const {textStore} = this.props.stores;
        let {id} = this.props;

        // TODO: Make also to const this is only for test purpose set to let
        let displayType = userStore.isAdmin ? "admin" : "user";

        let textToShow = {content: ""};
        let contentToShow = "";

        if (displayType === "admin" && typeof document !== "undefined") {
            contentToShow = <TextStoreHTMLEditor id={id}/>
        } else {
            let language = 'de-DE';

            if (this.props.i18n && typeof this.props.i18n.language !== "undefined") {
                language = this.props.i18n.language;
            }

            if (typeof textStore.texts[id] !== "undefined") {
                if (typeof textStore.texts[id][language] !== "undefined") {
                    textToShow = textStore.texts[id][language];
                } else {
                    textToShow = {content: ""};
                }
            }

            contentToShow = <div id={id} dangerouslySetInnerHTML={{__html: textToShow.content}}/>;

        }
        return (
            contentToShow
        );
    }
}

EditHTMLComponent.propTypes = {
    id: PropTypes.string,
};

EditHTMLComponent.defaultProps = {
    id: Math.random().toString()
};

module.exports = EditHTMLComponent;
