import React from "react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import history from "../helper/browserHistory";
import {observable} from "mobx";
import config from "../../config/main.config";
import {withTranslation} from "react-i18next";
import {observer} from "mobx-react";
import {getTokenFromLocalStorage} from "../helper/util";
import SeparatorRow from "../components/SeparatorRow";
import {Card} from "semantic-ui-react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import EditCardComponent from "../components/EditCardComponent";
import EditHTMLComponent from "../components/EditHTMLComponent";

@withTranslation()
@observer
export default class UserOverviewPage extends React.Component {

    @observable
    questionnaires = [];

    @observable
    cacheId = "";

    componentDidMount() {
        window.scrollTo(0, 0);
        this.fetchQuestionnaires();
        this.fetchCached();
    }

    fetchQuestionnaires() {
        let self = this;
        //Get all products
        fetch(config.BASE_URL + "questionnaires/" + this.props.i18n.language + "/active", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    self.questionnaires = json;
                    self.setState({});
                });

            }
        }).catch(
            error => {
                //console.log(error);
                if (error.status === 401) {
                    history.replace("/" + this.props.i18n.language + "/login");
                }
            }
        );
    }

    fetchCached() {
        let self = this;
        //Get all products
        fetch(config.BASE_URL + "feedbacks/is_cached", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    if (json.hasCached) {
                        self.cacheId = json.questionnaireId;
                        self.setState({});
                    }
                });

            }
        }).catch(
            error => {
                //console.log(error);
                if (error.status === 401) {
                    history.replace("/" + self.props.i18n.language + "/login");
                }
            }
        );
    }

    render() {
        let {i18n} = this.props;

        let showQuestionnaires = this.questionnaires.map((questionnaire, index) =>
            <Card fluid className={"card-shadow engagement-card"} key={index}>
                <Card.Content>
                    <Card.Header>
                        <div className={"card-title-container"}>
                            <Image
                                alt={"Bild zur Überschrift"}
                                src={config.PUBLIC_URL + "images/orange.png"}
                                className={"card-bullet-point"}
                            />
                            <h3 className={"card-header"}>
                                {questionnaire.title[i18n.language]}
                            </h3>
                        </div>
                    </Card.Header>
                    <Card.Description textAlign={"center"}>
                        <p className={"card-text"}
                           dangerouslySetInnerHTML={{__html: questionnaire.info[i18n.language]}}/>
                        <div className={"card-button-bottom"}>
                            <Button
                                className={"card-button-orange"}
                                onClick={() =>
                                    history.push(
                                        "/" + this.props.i18n.language + "/questionnaire/" + questionnaire.questionnaireId
                                    )}>
                                {this.cacheId === questionnaire.id ?
                                    i18n.t("user_overview.cache_subtitle").toUpperCase()
                                    :
                                    i18n.t("user_overview.subtitle").toUpperCase()
                                }
                                <Image
                                    alt={"Bild zum Link"}
                                    spaced
                                    style={{height: "14px", width: "20px", marginBottom: "1px"}}
                                    src={config.PUBLIC_URL + "images/icons/arrow-right-orange.svg"}/>
                            </Button>
                        </div>
                    </Card.Description>
                </Card.Content>
            </Card>)
        showQuestionnaires.push(<EditCardComponent
            key={"kasu"}
            headerId={"ask-kasu-overview-title-1-id"}
            subheaderId={"ask-kasu-overview-text-1-id"}
            buttonId={"ask-kasu-overview-button-1-id"}
            buttonClass={"card-button-orange"}
            wideWidth={4}
            color={"orange"}
            computerStyle={{marginBottom: "25px"}}
            mobileStyle={{marginBottom: "11px"}}
        />)
        showQuestionnaires.push(<EditCardComponent
            headerId={"ask-user-overview-title-1-id"}
            subheaderId={"ask-user-overview-text-1-id"}
            buttonId={"ask-user-overview-button-1-id"}
            buttonClass={"card-button-orange"}
            wideWidth={4}
            color={"orange"}
            computerStyle={{marginBottom: "25px"}}
            mobileStyle={{marginBottom: "11px"}}
        />)

        return (
            <Grid>
                <SeparatorRow/>
                <Grid.Row centered>
                    <Grid.Column computer={10} tablet={12} mobile={15} textAlign={"center"}>
                        <h2 className={"landing-title-header"}>
                            {i18n.t("user_overview.title")}
                        </h2>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column computer={10} tablet={12} mobile={15} textAlign={"center"}>
                        <Card.Group itemsPerRow={2} stackable>
                            {showQuestionnaires}
                        </Card.Group>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column computer={10} tablet={12} mobile={15} textAlign={"center"}>
                        <EditHTMLComponent id={"user-overview-text-1"}/>
                    </Grid.Column>
                    {/*
                    <Grid.Column computer={10} tablet={12} mobile={15} textAlign={"center"}>
                        <Button
                            className={"submit-button"}
                            style={{
                                backgroundImage: " url('" + config.PUBLIC_URL + "images/buttonorange.png" + "')"
                            }}
                            onClick={() => window.open("https://www.pekana.com/" + (i18n.language === "de-DE" ? "de-DE" : "en-EN") + "/kontakt", "_blank")}>
                            {i18n.t("contact").toUpperCase()}
                        </Button>
                    </Grid.Column>
                    */}
                </Grid.Row>
            </Grid>

        );
    }
}